<!-- =========================================================================================
    File Name: ProjectHome.vue
    Description: Page d'un projet
    ----------------------------------------------------------------------------------------
    Item Name: RIM-Nat / Plateforme 3D collaborative
    Author: Loïc ROYBON
    Author URL:
========================================================================================== -->
<template>
    <div id="project-page">
      <vs-popup title="" :active.sync="popupImageActive">
        <vs-row vs-align="center" vs-justify="center">
          <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
            <img class="img100" :src="popupImageUrl"/>
          </div>
        </vs-row>
      </vs-popup>
        <vs-progress v-if="!loaded" indeterminate color="primary"></vs-progress>
        <div v-else>

            <!-- CONTENU PUBLIC -->
            <div v-if="loggedIn || isPublicModel" class="vx-row">
                <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
                  <!-- ligne de titre -->
                  <vs-row class="mb-4">
                    <vs-col vs-justify="flex-start" vs-align="top" vs-w="8">
                      <template v-if="projectInfoEditionInProgress">
                        <vx-tooltip :text="$t('projectSlugEditMessage')" position="right" color="warning">
                          <p contenteditable="true" class="editable" 
                          @blur="projectSlugChange" 
                          @keydown.enter.prevent 
                          @keydown.space.prevent
                          >{{ this.project.project_slug }}</p>
                        </vx-tooltip>
                        <vx-tooltip :text="$t('projectNicenameEditMessage')" position="right" >
                          <h2 contenteditable="true" class="editable" 
                          @blur="projectNicenameChange" 
                          @keydown.enter.prevent
                          >{{ decodeURIComponent(this.project.project_nicename) }}</h2>
                        </vx-tooltip>
                        <div class="flex flex-wrap">
                          <vs-chip v-for="keyword in projectKeywords" :key="'keyword_'+keyword" closable @click="removeKeyword(keyword)">
                            #{{keyword}}
                          </vs-chip>
                          <vs-button @click="newKeywordFormActive = true" color="primary" size="small" icon-pack="feather" icon="icon-hash" type="flat">{{$t('newKeyword')}}</vs-button>
                          <vs-popup :title="$t('newKeyword')" :active.sync="newKeywordFormActive" class-content="keyword_popup">
                            <div class="flex" v-if="newKeywordsList != null && typeof newKeywordsList != 'string' && newKeywordsList != 'null'">
                              <vue-simple-suggest
                                class="w-5/6 px-4"
                                v-model="newKeyword"
                                :list="newKeywordsList"
                                :filter-by-query="true"
                                @input="newKeyword = newKeyword.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')"
                                @blur="newKeywordSanitize()"
                                @keydown.enter="newKeywordFormValidate()">
                              </vue-simple-suggest>
                              <vs-button 
                                class="w-1/6 px-4"
                                :disabled="newKeyword.length < 3" @click="newKeywordFormValidate()">{{$t('Ok')}}</vs-button>
                            </div>
                          </vs-popup>                         
                        </div>
                      </template>
                      <template v-else>
                        <p>{{ this.project.project_slug }}</p>
                        <h2>{{ decodeURIComponent(this.project.project_nicename)}}</h2>
                        <div class="flex cursor-pointer">
                          <vs-chip v-for="keyword in projectKeywords" :key="'keyword_'+keyword" >
                            <vs-avatar icon-pack="feather" icon="icon-hash" />
                            <a @click="gotoKeyword(keyword)" class="text-dark">{{keyword}}</a>
                          </vs-chip>
                        </div>
                      </template>
                    </vs-col>

                    <template v-if="adminByPass || this.can_see_page">
                      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="top" vs-w="4">
                        <!-- bouton d'édition si user peut customize le project -->
                        <template v-if="adminByPass || this.current_user_project_caps.can_project_customize == '1'">
                          <vs-button v-if="!projectInfoEditionInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="getProjectInfostart()">{{ $t('Edit') }}</vs-button>
                          <div v-if="projectInfoEditionInProgress" class="btn-group-vertical">
                            <vs-button color="success" icon-pack="feather" icon="icon-save" @click="updateProjectInfoFinish()">   {{ $t('Save')   }}</vs-button>
                          </div>
                        </template>
                      </vs-col>
                    </template>
                  </vs-row>

                  <!-- indique si le projet est multi portals -->
                  <vs-row class="mb-4" v-if="multiPortalsProject">
                    <vs-alert 
                      icon-pack="feather" icon="icon-alert-circle"
                      :title="$t('multiPortalProjectTitle')"
                      active="true">
                      {{$t('multiPortalProjectMessage')}}
                      <li :key="po.portal_slug" v-for="po in projectPortals">
                        {{po.portal_nicename}}
                      </li>
                    </vs-alert>
                  </vs-row>

                    <vx-card 
                    class="overlay-card overflow-hidden"
                    >                     
                      <template slot="no-body">
                          <!-- champ d'upload si édition a commencé -->
                          <vs-row v-if="projectInfoEditionInProgress" vs-align="center" vs-type="flex" vs-justify="center">
                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
                              <vs-upload
                                fileName="file[]" 
                                automatic 
                                :action="`${this.$appConfig.imgBaseUrl}/upload.php?entity=project&type=cover&id=${this.project.project_id}`" 
                                :text="$t('choseImage')"
                                limit="1"
                                multiple="false"
                                accept=".jpg, .png"
                                @on-success="coverUploadSuccess" 
                                @on-error="coverUploadError" 
                                @change="coverUploadStart"
                              />
                            </vs-col>
                            <vs-col vs-justify="flex-start" vs-align="center" vs-w="5">
                              <template v-if="coverImageUploading">
                                <p>{{ $t('treatmentInProgress') }}</p>
                                <vs-progress  indeterminate color="primary"></vs-progress>
                              </template>
                              <template v-else>
                                <p>{{ $t('choseImageToChangeProjectCover') }}</p>
                              </template>
                            </vs-col>
                          </vs-row>
                          <!-- image si pas upload en cours -->
                          <template v-if="!projectInfoEditionInProgress">
                            <img
                            :src="`${this.$appConfig.imgBaseUrl}/project/cover/${this.project.project_id}/${this.thumbName}?${this.projectCoverImageKey}`" 
                            @error="imageUrlAlt" 
                            alt="cover image" 
                            class="responsive"
                            :key="projectCoverImageKey"
                            >
                            <div v-if="isPublicModel" class="ribbon ribbon-top-right">
                              <span>{{$t('publicModel')}}</span>
                            </div>
                          </template>
                      </template>
                    </vx-card>
                </div>
            </div>

            <div v-if="!loggedIn">
              <vx-card>
                <!-- login form -->
                <div class="mt-2">
                  <p class="mt-2">{{$t('loginRequiredMessage')}}</p>
                  <!-- <vs-alert color="danger" class="mt-5" icon-pack="feather" icon="icon-rotate-cw">{{$t('migrationLoginMessage')}}</vs-alert> -->
                  <vs-button class="mt-4" @click="login" color="primary">{{$t('loginOrRegister')}}</vs-button>
                </div>
              </vx-card>
            </div>

            <!-- Contenu si accès au projet est autorisé -->
            <div v-else-if="adminByPass || can_see_page">
                <!-- Gestion des demandes d'accès -->
                <template v-if="showAccessRequestCard">
                  <vx-card
                    :title="$t('ProjectAccessRequests')"
                    :subtitle="$t('ProjectAccessRequestsExplainations')"
                    title-color="#000"
                    subtitle-color="#000"
                    card-background="warning" 
                    class="mb-8">
                    <template v-for="req in accessRequests">
                      <div v-if="req.sent == '1' && req.closed == '0'" :key="req.request_hash">
                        <div class="flex cursor-pointer" @click="gotoProjectAccessRequest(req.request_hash)">
                          <vs-chip class="flex-1 mt-1" color="dark">
                            <user-avatar :user="req"/>
                            {{req.first_name | capitalize}} {{req.last_name | capitalize}} | {{req.sql_timestamp | longDate}}
                          </vs-chip>
                        </div>
                      </div>
                    </template>
                  </vx-card>
                </template>
                <!-- Modèle 3D -->
                <div class="vx-row">
                  <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
                    <vx-card
                      :title="$t('Model3D')"
                      title-color="#000"
                      content-color="#fff"
                      card-background="white">
                      <vs-row vs-align="center" vs-justify="center">
                        <vs-alert v-if="!diagnosticDone" 
                          color="warning" active="true" class="mb-2">
                          <span v-if="!diagnosticDone" class="md:flex hidden items-center">
                              <feather-icon icon="AlertTriangleIcon" svgClasses="stroke-current text-warning w-6 h-6" class="ml-2" />
                              <h6 class="mx-2">
                                  {{ $t('diagnosticNotDone')}}.
                                  <a class="text-warning font-bold" :href="diagUrlWithRedirect">{{$t('gotoDiagnostic')}}</a>
                              </h6>
                          </span>
                        </vs-alert>
                        <vs-progress v-if="!projectLayersLoaded" indeterminate color="primary"></vs-progress>
                        <template v-else>
                          <vs-alert v-if="layersCount == 0" 
                            color="warning" active="true">
                            {{ $t('NoLayersInThisModel') }}
                          </vs-alert>
                          <template v-else>
                            <!-- Si accès autorisé -->
                            <div 
                              v-if="isPublicModel || adminByPass || this.current_user_project_caps.can_project_model_see === '1' || this.current_user_portal_caps.can_portal_projects_manage === '1'"
                              class="justify-center block content-center w-full">
                              
                              <!-- Boutons d'accès au modèle, uniquement après diagnostique obligatoire -->
                              <div v-if="diagnosticDone" class="block w-full" >
                                <div v-if="diagnosticDone" :class="rdpAccessEnabled ? 'flex ' : 'block'">

                                  <!-- Accès via GPU client -->
                                  <div class="block px-6" :class="rdpAccessEnabled ? 'w-1/2 ' : 'w-full'">
                                    <vs-button 
                                      class="w-full" 
                                      target :href="modelUrl"
                                      :size="rdpAccessEnabled && rdpAccessAvailable.value && rdpAccessRecommended.value ? 'medium' : 'large'" 
                                      :type="rdpAccessEnabled && rdpAccessAvailable.value && rdpAccessRecommended.value ? 'line' : 'filled'" 
                                      :color="rdpAccessEnabled && rdpAccessAvailable.value && rdpAccessRecommended.value ? 'grey' : 'primary'"
                                      >
                                      <p v-if="rdpAccessEnabled" class="font-semibold">{{ $t('AccessToModelDirect') }}</p>
                                      <p v-else class="font-semibold">{{ $t('AccessToModel') }}</p>

                                      <template v-if="rdpAccessEnabled && rdpAccessAvailable.value">
                                        <p v-if="rdpAccessRecommended.value == false" class="text-xs" >{{ $t('rdpOptimalSolutionMessage') }}</p>
                                        <p v-else class="text-xs" >{{ $t('rdpNotRecommendedSolutionMessage') }}</p>
                                      </template>
                                    </vs-button>
                                  </div>
                                  <!-- Accès via GPU serveur -->
                                  <div v-if="rdpAccessEnabled" class="block px-6" :class="rdpAccessEnabled ? 'w-1/2 ' : 'w-full'">
                                    <vs-button 
                                      :disabled="!rdpAccessAvailable.value"
                                      @click="getRdpData('Chrome')" 
                                      class="w-full" 
                                      :size="rdpAccessEnabled && rdpAccessAvailable.value && rdpAccessRecommended.value ? 'large' : 'medium'" 
                                      :type="rdpAccessEnabled && rdpAccessAvailable.value && rdpAccessRecommended.value ? 'filled' : 'line'" 
                                      :color="rdpAccessEnabled && rdpAccessAvailable.value && rdpAccessRecommended.value ? 'primary' : 'grey'"
                                      >
                                      <p class="font-semibold">{{ $t('AccessToModelRdp') }}</p>
                                      <template v-if="rdpAccessEnabled && rdpAccessAvailable.value">
                                        <p class="text-xs" v-if="rdpAccessRecommended.value == true">{{ $t('rdpRecommendedSolutionMessage') }}</p>
                                        <p class="text-xs" v-else>{{ $t('rdpNotRecommendedSolutionMessage') }}</p>  
                                      </template>                                
                                    </vs-button>
                                    <vs-popup v-if="this.rdpContent != ''" :title="$t('rdpAccessTitle')" :active.sync="rdpPopupActive">
                                      <p>{{$t('rdpAccessMessage')}}</p>
                                      <vs-input v-model="this.rdpPassword" class="inline-flex" />
                                      <vs-button 
                                        v-if="this.rdpPassword != ''" 
                                        v-clipboard:copy="this.rdpPassword"
                                        @click="rdpPasswordCopiedAlert()"
                                        class="mt-4 w-full">
                                        {{$t('CopyPassword')}}
                                      </vs-button>
                                      <vs-alert color="danger" >{{$t('rdpAccessLimitationsmessage')}}</vs-alert>
                                    </vs-popup>
                                  </div>
                                </div>
                                <div v-if="rdpAccessEnabled" class="block w-full px-6 pt-6 pb-2">
                                  <vs-alert v-if="rdpAccessAvailable.value" icon="info" color="primary" >{{$t('rdpExplaination')}}</vs-alert>
                                  <vs-alert v-else icon="info" color="danger" >{{$t('rdpUnavailable') }} ({{ rdpAccessAvailable.cause }})</vs-alert>
                                </div>
                              </div>
                            </div>

                            <!-- Sinon -->
                            <vs-alert v-else color="warning" active="true">
                              <p>{{ $t('ModelAccessForbidden') }}</p>
                            </vs-alert>
                          </template>
                        </template>
                      </vs-row>
                    </vx-card>
                  </div>
                    
                </div>

                <!-- Ligne 2 -->
                <div class="vx-row" v-if="this.$store.state.projectsLocation">
                    <!-- Maps -->
                    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
                        <vx-card
                            :title="$t('ProjectLocation')"
                            title-color="#000"
                            content-color="#fff"
                            card-background="white">

                            <!-- bouton d'édition si user peut customize le project -->
                            <template v-if="adminByPass || (this.current_user_project_caps.can_project_customize == '1' && this.can_see_page)" slot="actions">
                              <vs-button v-if="!markerEditionInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="updateProjectLocationStart()">{{ $t('Edit') }}</vs-button>
                              <div v-if="markerEditionInProgress" class="btn-group-vertical">
                                <vs-button :disabled="!markerMoved" color="success" type="line" icon-pack="feather" icon="icon-save" @click="updateProjectLocationSave()">   {{ $t('Save')   }}</vs-button>
                                <vs-button color="danger" type="line" icon-pack="feather" icon="icon-x" @click="updateProjectLocationCancel()"> {{ $t('Cancel') }}</vs-button>
                              </div>
                            </template>

                            <!-- champ de recherche si édition a commencé -->
                            <div v-if="markerEditionInProgress" class="flex mb-0">
                              <div class="w-full bg-grid-color h-12">
                                <gmap-autocomplete class="introInput" @place_changed="setPlace">
                                  <template v-slot:input="slotProps">
                                    <vs-input class="inputx"
                                      :placeholder="$t('PlaceSearchPlaceholder')"
                                      ref="input"
                                      v-on:listeners="slotProps.listeners"
                                      v-on:attrs="slotProps.attrs" />
                                  </template>
                                </gmap-autocomplete>
                              </div>
                            </div>

                            <vs-row vs-align="center" vs-type="flex" vs-justify="center">
                              <!-- si position est définie on affiche la carte -->
                              <div class="w-full" v-if="this.project_lat !== 0 && this.project_lng !== 0 && !isNaN(this.project_lat) && !isNaN(this.project_lng)">                                  
                                <gmap-map :key="this.incrementedMapKey" :center="center" :zoom="12" :style="this.map_style" map-type-id="terrain">
                                  <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false"></gmap-info-window>
                                  <gmap-marker :key="i" v-for="(m,i) in markers" :position="m.position" 
                                  :clickable="true"
                                  :draggable="markerEditionInProgress"
                                  @dragend="updateProjectLocation($event.latLng)"></gmap-marker>
                                </gmap-map> 
                              </div>
                              <!-- sinon -->
                              <vs-alert v-else color="warning" active="true">
                                  <p>{{ $t('ModelLocationUnknown') }}</p>
                              </vs-alert>
                            </vs-row>
                        </vx-card>
                    </div>

                    <!-- Données 3D -->
                    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base" ref="layersCard">
                      <vx-card id="layersCard"
                        :title="$t('Datas3d')"
                        title-color="#000"
                        content-color="#000"
                        card-background="white">
                        
                        <!-- bouton d'édition si user peut manager les layers -->
                        <template v-if="adminByPass || this.current_user_project_caps.can_project_layers_manage == '1'" slot="actions">
                          <vs-button v-if="!layersManageInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="updateLayersStart()">{{ $t('Edit') }}</vs-button>
                          <div v-if="layersManageInProgress" class="btn-group-vertical">
                            <vs-button color="primary"  type="line" icon-pack="feather" icon="icon-plus" @click="openAddLayerSidebar()"> {{ $t('addLayer') }}</vs-button>
                            <vs-button color="danger"  type="line" icon-pack="feather" icon="icon-x"     @click="updateLayersFinish()"> {{ $t('Finish') }}</vs-button>
                            <vs-button color="danger"  type="line" icon-pack="feather" icon="icon-x"     @click="deleteSelectedLayers()"> {{ $t('deleteSelectedLayers') }} ({{ bulkEditSelection.length }})</vs-button>
                          </div>
                        </template>
                        <vs-row vs-align="flex-end" vs-type="flex" vs-justify="space-around" vs-w="w-full">

                          <!-- sidebar -->
                          <vs-sidebar class="sidebarx" position-right parent="#layersCard" :hidden-background="true" default-index="1" 
                          color="primary" v-model="layersSidebarVisible">
                            <div class="header-sidebar" slot="header">
                              <p>{{ $t('addLayer') }}</p>
                            </div>
                            
                            <vs-tabs>
                              <vs-tab :label="$t('unusedLayers')" icon-pack="feather" icon="icon-sunrise">
                                <vs-list>
                                  <div class="" :style="addLayersListStyle">
                                    <vs-list-item :key="i" v-for="(l,i) in portalUnusedLayers" 
                                    :subtitle="decodeURIComponent(l.layer_name)" 
                                    class="line-break"
                                    >
                                      <table class="addLayerActions">
                                        <tr>
                                          <td v-if="l.layer_type != 'shapefile' && l.layer_type != 'dao'"><vs-button size="small" color="grey" type="border" icon-pack="feather" icon="icon-eye"></vs-button></td>
                                          <td><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addLayerConfirm(l)"></vs-button></td>
                                        </tr>
                                      </table>
                                    </vs-list-item>
                                  </div>
                                </vs-list>
                                <div class="sidebar-alert" v-if="portalUnusedLayers.length === 0" >
                                  <vs-alert active="true">
                                    {{ $t('noUnusedLayersToShow') }}
                                  </vs-alert>
                                </div>
                              </vs-tab>

                              <vs-tab :label="$t('usedLayers')">
                                <vs-list>
                                  <div class="scrollable" :style="addLayersListStyle">
                                    <vs-list-item :key="i" v-for="(l,i) in portalUsedLayers" 
                                    :subtitle="decodeURIComponent(l.layer_name)" 
                                    class="line-break"
                                    >
                                      <table class="addLayerActions">
                                        <tr>
                                          <td v-if="l.layer_type != 'shapefile' && l.layer_type != 'dao'"><vs-button size="small" color="grey" type="border" icon-pack="feather" icon="icon-eye"></vs-button></td>
                                          <td><vs-button size="small" color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addLayerConfirm(l)"></vs-button></td>
                                        </tr>
                                      </table>
                                    </vs-list-item>
                                  </div>
                                </vs-list>
                              </vs-tab>
                            </vs-tabs>
                          </vs-sidebar>
    
                          <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base">
                            <vs-collapse accordion :key="projectLayersVersion">

                              <!-- POINTCLOUD -->
                              <vs-collapse-item v-if="displayFeature('pointcloud')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('pointcloud')" icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('Pointclouds') }} ( {{ this.layersPointcloud.length }} )
                                </div>  
                                <vs-alert v-if="this.layersPointcloud.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list>                           
                                  <template v-for="project_layer in this.layersPointcloud" >
                                    <vs-list-item :key="project_layer.layer_id" icon-pack="feather" icon="icon-cloud" :subtitle="decodeURIComponent(project_layer.layer_name)">
                                      <div class="layer_actions">
                                        <!-- Actions sur les pointclouds -->
                                        <vs-row vs-align="center" vs-type="flex" vs-justify="space-around">     
                                          <!-- Visible par défaut -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                                            <vx-tooltip :text="$t('default_visibility')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'1' == project_layer.default_visibility" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_visibility', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Groupe -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                                            <vx-tooltip :text="$t('groupe')">
                                              <p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Style d'affichage des points -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_style')">
                                              <vs-dropdown vs-trigger-click :disabled="!layersManageInProgress">
                                                <vs-button size="small" class="btn-drop" type="flat" icon="edit">
                                                  <span key="1" :ref="`${project_layer.layer_id}_default_pointcloud_material_style`">{{ project_layer.default_pointcloud_material_style ? project_layer.default_pointcloud_material_style  : "RGB" }}</span>
                                                </vs-button>               
                                                <vs-dropdown-menu>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'RGB', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('RGB') }}
                                                  </vs-dropdown-item>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'INTENSITY', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('INTENSITY') }}
                                                  </vs-dropdown-item>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'HEIGHT', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('HEIGHT') }}
                                                  </vs-dropdown-item>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'COLOR', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('COLOR') }}
                                                  </vs-dropdown-item>
                                                </vs-dropdown-menu>
                                              </vs-dropdown>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Couleur forcée -->
                                          <vs-col v-show="DisplayColorPicker[project_layer.layer_id]"
                                          vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_color')">
                                              <input type="color" 
                                              :disabled="!layersManageInProgress"
                                              :v-model="ForcedColors[project_layer.layer_id]"  
                                              :value="ForcedColors[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_color', $event, project_layer.layer_id)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Taille des pixels -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_size')">
                                              <input type="number" class="input_decimal"
                                              :disabled="!layersManageInProgress"
                                              :v-model="PixelSizes[project_layer.layer_id]"  
                                              :value="PixelSizes[project_layer.layer_id]" 
                                              min="0" max="3" step=".5"
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_size', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Taille adaptative des pixels -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_pointsizetype')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'ADAPTIVE'===project_layer.default_pointcloud_material_pointsizetype || !project_layer.default_pointcloud_material_pointsizetype" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_pointsizetype', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Début de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="2">
                                            <vx-tooltip :text="$t('layer_4d_min')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Min4D[project_layer.layer_id]"  
                                              :value="Min4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_min', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Fin de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="2">
                                            <vx-tooltip :text="$t('layer_4d_max')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Max4D[project_layer.layer_id]"  
                                              :value="Max4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_max', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Retrait des dates 4D -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                              <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                <vs-button 
                                                :disabled="!layersManageInProgress"
                                                color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                @click="removeProjectLayer4dPlanification(project_layer.layer_id)"
                                                ></vs-button>
                                              </vx-tooltip>
                                            </vs-col>
                                          </template>
                                          <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                              <template v-if="layersManageInProgress">
                                                <vx-tooltip :text="$t('SelectProjectLayer')">
                                                  <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                    @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                  </vs-checkbox>
                                                </vx-tooltip>
                                              </template>
                                            </vs-col>
                                          </template>

                                        </vs-row>
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                              <!-- STRUCTURED POINTCLOUD -->
                              <vs-collapse-item v-if="displayFeature('structured_pointcloud')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('structured_pointcloud')" icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('StructuredPointclouds') }} ( {{ this.layersStructuredPointcloud.length }} )
                                </div>  
                                <vs-alert v-if="this.layersStructuredPointcloud.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list>                           
                                  <template v-for="project_layer in this.layersStructuredPointcloud" >
                                    <vs-list-item :key="project_layer.layer_id" icon-pack="feather" icon="icon-cloud" :subtitle="decodeURIComponent(project_layer.layer_name)">
                                      <div class="layer_actions">
                                      <!-- Actions sur les pointclouds -->
                                        <vs-row vs-align="center" vs-type="flex" vs-justify="space-around">     
                                          <!-- Visible par défaut -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                                            <vx-tooltip :text="$t('default_visibility')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'1' == project_layer.default_visibility" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_visibility', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Groupe -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                                            <vx-tooltip :text="$t('groupe')">
                                              <p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Style d'affichage des points -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_style')">
                                              <vs-dropdown vs-trigger-click :disabled="!layersManageInProgress">
                                                <vs-button size="small" class="btn-drop" type="flat" icon="edit">
                                                  <span key="1" :ref="`${project_layer.layer_id}_default_pointcloud_material_style`">{{ project_layer.default_pointcloud_material_style ? project_layer.default_pointcloud_material_style  : "RGB" }}</span>
                                                </vs-button>               
                                                <vs-dropdown-menu>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'RGB', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('RGB') }}
                                                  </vs-dropdown-item>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'INTENSITY', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('INTENSITY') }}
                                                  </vs-dropdown-item>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'HEIGHT', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('HEIGHT') }}
                                                  </vs-dropdown-item>
                                                  <vs-dropdown-item 
                                                    @click="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_style', 'COLOR', `${project_layer.layer_id}_default_pointcloud_material_style`)" >{{ $t('COLOR') }}
                                                  </vs-dropdown-item>
                                                </vs-dropdown-menu>
                                              </vs-dropdown>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Couleur forcée -->
                                          <vs-col v-show="DisplayColorPicker[project_layer.layer_id]"
                                          vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_color')">
                                              <input type="color" 
                                              :disabled="!layersManageInProgress"
                                              :v-model="ForcedColors[project_layer.layer_id]"  
                                              :value="ForcedColors[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_color', $event, project_layer.layer_id)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Taille des pixels -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_size')">
                                              <input type="number" class="input_decimal"
                                              :disabled="!layersManageInProgress"
                                              :v-model="PixelSizes[project_layer.layer_id]"  
                                              :value="PixelSizes[project_layer.layer_id]" 
                                              min="0" max="3" step=".5"
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_size', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Taille adaptative des pixels -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                            <vx-tooltip :text="$t('default_pointcloud_material_pointsizetype')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'ADAPTIVE'===project_layer.default_pointcloud_material_pointsizetype || !project_layer.default_pointcloud_material_pointsizetype" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_pointcloud_material_pointsizetype', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Début de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="2">
                                            <vx-tooltip :text="$t('layer_4d_min')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Min4D[project_layer.layer_id]"  
                                              :value="Min4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_min', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Fin de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="2">
                                            <vx-tooltip :text="$t('layer_4d_max')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Max4D[project_layer.layer_id]"  
                                              :value="Max4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_max', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Retrait des dates 4D -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                              <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                <vs-button 
                                                :disabled="!layersManageInProgress"
                                                color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                @click="removeProjectLayer4dPlanification(project_layer.layer_id)"
                                                ></vs-button>
                                              </vx-tooltip>
                                            </vs-col>
                                          </template>
                                          <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                              <template v-if="layersManageInProgress">
                                                <vx-tooltip :text="$t('SelectProjectLayer')">
                                                  <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                    @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                  </vs-checkbox>
                                                </vx-tooltip>
                                              </template>
                                            </vs-col>
                                          </template>

                                        </vs-row>
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                              <!-- MESHES -->
                              <vs-collapse-item v-if="displayFeature('mesh')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('mesh')" icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('Meshes') }} ( {{ this.layersMesh.length }} )
                                </div>  
                                <vs-alert v-if="this.layersMesh.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list>                            
                                  <template v-for="project_layer in this.layersMesh" >
                                    <vs-list-item :key="project_layer.layer_id" icon-pack="feather" icon="icon-triangle" :subtitle="decodeURIComponent(project_layer.layer_name)">
                                      <!-- Actions sur les meshs -->
                                      <div class="layer_actions">
                                        <vs-row :key="project_layer.layer_id" vs-align="center" vs-type="flex" vs-justify="space-around"> 

                                          <!-- Visible par défaut -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                                            <vx-tooltip :text="$t('default_visibility')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'1' == project_layer.default_visibility" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_visibility', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Groupe -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                                            <vx-tooltip :text="$t('groupe')">
                                              <p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Début de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="3">
                                            <vx-tooltip :text="$t('layer_4d_min')">
                                              <input ttype="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Min4D[project_layer.layer_id]"  
                                              :value="Min4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_min', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Fin de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="3">
                                            <vx-tooltip :text="$t('layer_4d_max')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Max4D[project_layer.layer_id]"  
                                              :value="Max4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_max', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Retrait des dates 4D -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                              <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                <vs-button 
                                                :disabled="!layersManageInProgress"
                                                color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                @click="removeProjectLayer4dPlanification(project_layer.layer_id)"
                                                ></vs-button>
                                              </vx-tooltip>
                                            </vs-col>
                                          </template>                                          
                                          <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                              <template v-if="layersManageInProgress">
                                                <vx-tooltip :text="$t('SelectProjectLayer')">
                                                  <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                    @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                  </vs-checkbox>
                                                </vx-tooltip>
                                              </template>
                                            </vs-col>
                                          </template>

                                        </vs-row>
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                              <!-- BIMS -->
                              <vs-collapse-item v-if="displayFeature('bim')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('bim')" icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('Bims') }} ( {{ this.layersBim.length }} )
                                </div>  
                                <vs-alert v-if="this.layersBim.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list>                            
                                  <template v-for="project_layer in this.layersBim" >
                                    <vs-list-item :key="project_layer.layer_id" icon-pack="feather" icon="icon-triangle" :subtitle="decodeURIComponent(project_layer.layer_name)">
                                      <!-- Actions sur les bims -->
                                      <div class="layer_actions">
                                        <vs-row :key="project_layer.layer_id" vs-align="center" vs-type="flex" vs-justify="space-around"> 

                                          <!-- Visible par défaut -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                                            <vx-tooltip :text="$t('default_visibility')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'1' == project_layer.default_visibility" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_visibility', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Groupe -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                                            <vx-tooltip :text="$t('groupe')">
                                              <p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Début de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="3">
                                            <vx-tooltip :text="$t('layer_4d_min')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Min4D[project_layer.layer_id]"  
                                              :value="Min4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_min', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Fin de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="3">
                                            <vx-tooltip :text="$t('layer_4d_max')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Max4D[project_layer.layer_id]"  
                                              :value="Max4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_max', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Retrait des dates 4D -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                              <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                <vs-button 
                                                :disabled="!layersManageInProgress"
                                                color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                @click="removeProjectLayer4dPlanification(project_layer.layer_id)"
                                                ></vs-button>
                                              </vx-tooltip>
                                            </vs-col>
                                          </template>  
                                          <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                              <template v-if="layersManageInProgress">
                                                <vx-tooltip :text="$t('SelectProjectLayer')">
                                                  <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                    @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                  </vs-checkbox>
                                                </vx-tooltip>
                                              </template>
                                            </vs-col>
                                          </template>

                                        </vs-row>
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                              <!-- SHAPEFILES -->
                              <vs-collapse-item v-if="displayFeature('shapefile')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('shapefile')" icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('Shapefiles') }} ( {{ this.layersShapefile.length }} )
                                </div>  
                                <vs-alert v-if="this.project.project_coordinate_system == null || this.project.project_coordinate_system == '0'" color="warning">
                                  {{ $t('coordinatesSystemRequired') }}
                                </vs-alert>                                  
                                <vs-alert v-else-if="this.layersShapefile.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list>                            
                                  <template v-for="project_layer in this.layersShapefile" >
                                    <vs-list-item :key="project_layer.layer_id" class="mt-4">
                                      <!-- Actions sur les shapefiles -->
                                      <!-- titre stylisé -->
                                      <div class="w-full" :style="shpLayerStyle(project_layer)">
                                        <span class="mt-4 w-1/6 font-bold">{{decodeURIComponent(project_layer.layer_name)}}</span>
                                      </div>

                                      <div class="flex">
                                            <vs-tabs position="left" alignment="fixed" class="flex-1">

                                            <!-- Visible par défaut -->
                                              <vs-tab :label="$t('default_visibility')">
                                                  <vs-checkbox :disabled="!layersManageInProgress" :value="'1' == project_layer.default_visibility" 
                                                  @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_visibility', $event)">
                                                  {{$t('default_visibility')}}</vs-checkbox>
                                                  <!-- Groupe -->
                                                  <vx-tooltip :text="$t('groupe')" class="mt-6" >
                                                    {{$t('groupe')}}<p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                                  </vx-tooltip>
                                              </vs-tab>

                                              <!-- Remplissage de polygone -->
                                              <vs-tab :label="$t('shp_polygon_filled')">
                                                <div class="tab-text">
                                                  <vs-checkbox :disabled="!layersManageInProgress" :value="'1'===project_layer.shp_polygon_filled" 
                                                    @change="set_ProjectLayerParameter(project_layer.layer_id, 'shp_polygon_filled', $event)">
                                                    {{$t('shp_polygon_filled')}}
                                                  </vs-checkbox>
                                                  <vx-tooltip :text="$t('shp_polygon_fill_color')">
                                                    <div style="font-family: monospace">
                                                      <span class="ml-2 mt-2 layerColorPreview" :style="'font-size: 1.5rem; color:'+shpFillColors[project_layer.layer_id]">⬤</span>
                                                      {{ shpFillColors[project_layer.layer_id] }}
                                                    </div>
                                                    <color-picker 
                                                      v-show="layersManageInProgress"
                                                      :color="shpFillColors[project_layer.layer_id] || '#000'"
                                                      @change="shpFillColors[project_layer.layer_id] = $event.hex" 
                                                    />
                                                    <vs-button @click="set_ProjectLayerParameter(project_layer.layer_id, 'shp_polygon_fill_color', shpFillColors[project_layer.layer_id], project_layer.layer_id)" class="mt-2 mx-5 w-1/4 ml-auto text-center" color="primary" type="filled" size="small">{{$t('Save')}}</vs-button>

                                                  </vx-tooltip>
                                                </div>
                                              </vs-tab>

                                              <!-- Contour de polygone -->
                                              <vs-tab :label="$t('shp_polygon_outline')">
                                                <div class="tab-text">

                                                  <vs-checkbox :disabled="!layersManageInProgress" :value="'1'===project_layer.shp_polygon_outlined" 
                                                    @change="set_ProjectLayerParameter(project_layer.layer_id, 'shp_polygon_outlined', $event)">
                                                    {{$t('shp_polygon_outlined')}}
                                                  </vs-checkbox>

                                                  <vs-input-number :disabled="!layersManageInProgress" v-model="project_layer.shp_polygon_outline_width"
                                                    :label="$t('shp_polygon_outline_width')"
                                                    @input="set_ProjectLayerParameter(project_layer.layer_id, 'shp_polygon_outline_width', $event)"
                                                  />

                                                  <vx-tooltip :text="$t('shp_polygon_outline_color')">
                                                    <div style="font-family: monospace">
                                                      <span class="ml-2 mt-2" :style="'font-size: 1.5rem; color:'+shpOutlineColors[project_layer.layer_id]">◯</span>
                                                      {{ shpOutlineColors[project_layer.layer_id] }}
                                                    </div>
                                                    <color-picker 
                                                      v-show="layersManageInProgress"
                                                      :color="shpOutlineColors[project_layer.layer_id] || '#000'" 
                                                      @change="shpOutlineColors[project_layer.layer_id] = $event.hex" 
                                                    />
                                                    <vs-button @click="set_ProjectLayerParameter(project_layer.layer_id, 'shp_polygon_outline_color', shpOutlineColors[project_layer.layer_id], project_layer.layer_id)" class="mt-2 mx-5 w-1/4 ml-auto text-center" color="primary" type="filled" size="small">{{$t('Save')}}</vs-button>

                                                  </vx-tooltip>

                                                </div>
                                              </vs-tab>

                                              <!-- Points -->
                                              <vs-tab :label="$t('shp_point')">
                                                <div class="tab-text">
                                                  <vx-tooltip :text="$t('shp_point_color')">
<!-- 
                                                    <vs-input-number :disabled="!layersManageInProgress" :value="project_layer.shp_point_size || 1" v-model="project_layer.shp_point_size"
                                                      :label="$t('shp_point_size')"
                                                      @input="set_ProjectLayerParameter(project_layer.layer_id, 'shp_point_size', $event)"
                                                    /> -->
                                                    
                                                    <!-- <vs-checkbox :disabled="!layersManageInProgress" :value="'color'===project_layer.shp_point_style" 
                                                      @change="set_ProjectLayerParameter(project_layer.layer_id, 'shp_point_style', $event.target.checked == true ? 'color' : 'gradient')">
                                                      {{$t('shp_point_colored')}}
                                                    </vs-checkbox> -->

                                                    <vx-tooltip :text="$t('shp_point_color')">  
                                                      <div style="font-family: monospace">
                                                        <span class="ml-2 mt-2 layerColorPreview" :style="'font-size: 1.5rem; color:'+shpPointColors[project_layer.layer_id]">∴</span>
                                                        {{ shpPointColors[project_layer.layer_id] }}
                                                      </div>
                                                      <color-picker 
                                                        v-show="layersManageInProgress"
                                                        :color="shpPointColors[project_layer.layer_id] || '#000'" 
                                                        @change="shpPointColors[project_layer.layer_id] = $event.hex" 
                                                      />
                                                      <vs-button @click="set_ProjectLayerParameter(project_layer.layer_id, 'shp_point_color', shpPointColors[project_layer.layer_id], project_layer.layer_id)" class="mt-2 mx-5 w-1/4 ml-auto text-center" color="primary" type="filled" size="small">{{$t('Save')}}</vs-button>

                                                    </vx-tooltip>


                                                  </vx-tooltip>
                                                </div>
                                              </vs-tab>

                                              <!-- Comportement 3D -->
                                              <vs-tab :label="$t('shp_height_method')">
                                                <div class="tab-text">

                                                  <!-- Drappage sur le globe -->
                                                  <vs-checkbox :disabled="!layersManageInProgress" :value="'1'===project_layer.shp_polygon_clamp_to_ground"
                                                  class="mb-4" 
                                                  @change="set_ProjectLayerParameter(project_layer.layer_id, 'shp_polygon_clamp_to_ground', $event)">
                                                  {{$t('shp_polygon_clamp_to_ground')}}</vs-checkbox>

                                                  <!-- Affichage 3D brute -->
                                                  <vs-checkbox :disabled="!layersManageInProgress" :value="'1'===project_layer.shp_contains_height" 
                                                  class="mb-4" 
                                                  @change="set_ProjectLayerParameter(project_layer.layer_id, 'shp_contains_height', $event)">
                                                  {{$t('shp_contains_height')}}</vs-checkbox>

                                                </div>
                                              </vs-tab>

                                              <!-- Etiquette -->
                                              <!-- <vs-tab :label="$t('shp_tag')">
                                                <div class="tab-text">

                                                  <vs-checkbox :disabled="!layersManageInProgress" :value="'1'===project_layer.shp_tag" 
                                                  class="mb-4" 
                                                  @change="set_ProjectLayerParameter(project_layer.layer_id, 'shp_tag', $event)">
                                                  {{$t('shp_tag_explained')}}</vs-checkbox>

                                                </div>
                                              </vs-tab> -->

                                              <!-- 4D -->
                                              <vs-tab :label="$t('layer_4d')">
                                                <div class="flex">
                                                  
                                                  <!-- Début de slider -->
                                                  <vx-tooltip :text="$t('layer_4d_min')" class="flex-1">
                                                    <input type="number" class="" min="0"
                                                    :disabled="!layersManageInProgress"
                                                    :v-model="Min4D[project_layer.layer_id]"  
                                                    :value="Min4D[project_layer.layer_id]" 
                                                    @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_min', $event)" 
                                                    >
                                                  </vx-tooltip>

                                                  <!-- Fin de slider -->
                                                  <vx-tooltip :text="$t('layer_4d_max')" class="flex-1">
                                                    <input type="number" class="" min="0"
                                                    :disabled="!layersManageInProgress"
                                                    :v-model="Max4D[project_layer.layer_id]"  
                                                    :value="Max4D[project_layer.layer_id]" 
                                                    @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_max', $event)" 
                                                    >
                                                  </vx-tooltip>

                                                  <!-- Retrait des dates 4D -->
                                                  <div v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'" class="flex-1">
                                                    <!-- <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1"> -->
                                                      <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                        <vs-button 
                                                        :disabled="!layersManageInProgress"
                                                        color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                        @click="removeProjectLayer4dPlanification(project_layer.layer_id)"
                                                        ></vs-button>
                                                      </vx-tooltip>
                                                    <!-- </vs-col> -->
                                                  </div> 
                                                </div>
                                              </vs-tab>
                                            </vs-tabs>

                                          <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                              <template v-if="layersManageInProgress">
                                                <vx-tooltip :text="$t('SelectProjectLayer')">
                                                  <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                    @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                  </vs-checkbox>
                                                </vx-tooltip>
                                              </template>
                                            </vs-col>
                                          </template>

                                        <!-- </vs-row> -->
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                              <!-- DAO -->
                              <vs-collapse-item v-if="displayFeature('dao')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('dao')" icon="PackageIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('Daos') }} ( {{ this.layersDao.length }} )
                                </div>                                  
                                <vs-alert v-if="this.layersDao.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list v-if="this.layersDao.length > 0">                            
                                  <template v-for="(project_layer, layerIndex) in this.layersDao" >
                                    <vs-list-item :key="project_layer.layer_id" class="mt-4">
                                      <!-- Actions sur les DAO -->
                                      <div class="w-full">
                                        <span class="mt-4 w-1/6 font-bold">{{decodeURIComponent(project_layer.layer_name)}}</span>
                                      </div>

                                      <div class="flex">
                                        <vs-tabs position="left" alignment="fixed" class="flex-1">
                                          <vs-tab :label="$t('dxfLayersManager')">

                                            <!-- Paramètres à l'échelle d'un calque du fichier  -->
                                            <vs-tabs position="left" alignment="fixed" class="flex-1">
                                              
                                              <vs-tab v-for="(dxfLayer, dxfLayerIndex) in project_layer.dxf_layers_styles" :key="dxfLayer.layer_name" 
                                                :label="daoLayerHtmlTitle(dxfLayer)"
                                                >

                                                <!-- Calque DXF actif par défaut -->
                                                <vs-checkbox :disabled="!layersManageInProgress" 
                                                  :value="'1' === dxfLayer.layer_visible"
                                                  @change="layersDao[layerIndex].dxf_layers_styles[dxfLayerIndex].layer_visible = ($event.target.checked * 1).toString()">
                                                  {{$t('default_visibility')}}
                                                </vs-checkbox>

                                                <!-- Groupe -->
                                                <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                                                  <vx-tooltip :text="$t('groupe')">
                                                    <p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                                  </vx-tooltip>
                                                </vs-col>
                                                
                                                <div style="font-family: monospace">
                                                  <span class="ml-2 mt-2 layerColorPreview" :style="'font-size: 1.5rem; color:'+dxfLayer.layer_color">⬤</span>
                                                  {{ dxfLayer.layer_color }}
                                                </div>
                                                <div class="h-50 mb-10 mt-2">
                                                  <color-picker 
                                                    v-if="layersManageInProgress"
                                                    :color="dxfLayer.layer_color" 
                                                    @change="layersDao[layerIndex].dxf_layers_styles[dxfLayerIndex].layer_color = $event.hex" 
                                                  />
                                                </div>
                                                <div v-if="layersManageInProgress" class="mt-5 h-10">-</div>
                                              </vs-tab>

                                            </vs-tabs>
                                          </vs-tab>

                                          <!-- 4D -->
                                          <vs-tab :label="$t('layer_4d')">
                                            <div class="flex">
                                              
                                              <!-- Début de slider -->
                                              <vx-tooltip :text="$t('layer_4d_min')" class="flex-1">
                                                <input type="number" class="" min="0"
                                                :disabled="!layersManageInProgress"
                                                :v-model="Min4D[layersDao[layerIndex].layer_id]"  
                                                :value="Min4D[layersDao[layerIndex].layer_id]" 
                                                @change="set_ProjectLayerParameter(layersDao[layerIndex].layer_id, 'layer_4d_min', $event)" 
                                                >
                                              </vx-tooltip>

                                              <!-- Fin de slider -->
                                              <vx-tooltip :text="$t('layer_4d_max')" class="flex-1">
                                                <input type="number" class="" min="0"
                                                :disabled="!layersManageInProgress"
                                                :v-model="Max4D[project_layer.layer_id]"  
                                                :value="Max4D[project_layer.layer_id]" 
                                                @change="set_ProjectLayerParameter(layersDao[layerIndex].layer_id, 'layer_4d_max', $event)" 
                                                >
                                              </vx-tooltip>

                                              <!-- Retrait des dates 4D -->
                                              <div v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'" class="flex-1">
                                                <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                  <vs-button 
                                                  :disabled="!layersManageInProgress"
                                                  color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                  @click="removeProjectLayer4dPlanification(layersDao[layerIndex].layer_id)"
                                                  ></vs-button>
                                                </vx-tooltip>
                                              </div> 
                                            </div>
                                          </vs-tab>

                                        </vs-tabs>

                                        <vs-button v-if="layersManageInProgress" @click="set_ProjectLayerParameter(layersDao[layerIndex].layer_id, 'dxf_layers_styles', layersDao[layerIndex].dxf_layers_styles)" 
                                          class="text-center h-12 mt-2 mr-10" color="primary" type="filled">
                                            {{$t('Save')}}
                                        </vs-button>

                                        <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                        <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                            <template v-if="layersManageInProgress">
                                              <vx-tooltip :text="$t('SelectProjectLayer')">
                                                <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                  @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                </vs-checkbox>
                                              </vx-tooltip>
                                            </template>
                                          </vs-col>
                                        </template>
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                              <!-- PANO360 -->
                              <vs-collapse-item v-if="displayFeature('pano360')">
                                <div slot="header">
                                  <feather-icon v-if="isBetaFeature('dao')" icon="GlobeIcon" svgClasses="stroke-current text-primary w-6 h-6" class="ml-2" />
                                  {{ $t('Pano360') }} ( {{ this.layersPano360.length }} )
                                </div>                                  
                                <vs-alert v-if="this.layersPano360.length == 0" color="warning">
                                  {{ $t('NoLayersAllocatedForThisType') }}
                                </vs-alert>                                  
                                <vs-list v-if="this.layersPano360.length > 0">                            
                                  <template v-for="project_layer in this.layersPano360" >
                                    <vs-list-item :key="project_layer.layer_id" icon-pack="feather" icon="icon-globe" :subtitle="decodeURIComponent(project_layer.layer_name)">
                                      <!-- Actions sur les Pano360 -->
                                      <div class="layer_actions">
                                        <vs-row :key="project_layer.layer_id" vs-align="center" vs-type="flex" vs-justify="space-around"> 

                                          <!-- Visible par défaut -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="1">
                                            <vx-tooltip :text="$t('default_visibility')">
                                              <vs-checkbox :disabled="!layersManageInProgress" :value="'1' == project_layer.default_visibility" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'default_visibility', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Groupe -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
                                            <vx-tooltip :text="$t('groupe')">
                                              <p :contenteditable="layersManageInProgress" class="editable" @blur="set_ProjectLayerParameter(project_layer.layer_id, 'groupe', $event)" @keydown.enter.prevent >{{ project_layer.groupe }}</p>
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Début de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="3">
                                            <vx-tooltip :text="$t('layer_4d_min')">
                                              <input ttype="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Min4D[project_layer.layer_id]"  
                                              :value="Min4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_min', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Fin de slider -->
                                          <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="3">
                                            <vx-tooltip :text="$t('layer_4d_max')">
                                              <input type="number" class="input_decimal" min="0"
                                              :disabled="!layersManageInProgress"
                                              :v-model="Max4D[project_layer.layer_id]"  
                                              :value="Max4D[project_layer.layer_id]" 
                                              @change="set_ProjectLayerParameter(project_layer.layer_id, 'layer_4d_max', $event)" 
                                              >
                                            </vx-tooltip>
                                          </vs-col>
                                          <!-- Retrait des dates 4D -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="1">
                                              <vx-tooltip :text="$t('RemoveLayer4dPlanification')">
                                                <vs-button 
                                                :disabled="!layersManageInProgress"
                                                color="warning" type="flat" icon-pack="feather" icon="icon-delete"
                                                @click="removeProjectLayer4dPlanification(project_layer.layer_id)"
                                                ></vs-button>
                                              </vx-tooltip>
                                            </vs-col>
                                          </template>                                          
                                          <!-- Retrait d'un layer (visible uniquement si user can_project_layers_manage) -->
                                          <template v-if="adminByPass || current_user_project_caps.can_project_layers_manage == '1'">
                                            <vs-col vs-type="flex" vs-justify="center" vs-align="space-around" vs-w="0">
                                              <template v-if="layersManageInProgress">
                                                <vx-tooltip :text="$t('SelectProjectLayer')">
                                                  <vs-checkbox :label="$t('RemoveProjectLayers')" color="danger" type="flat" icon-pack="feather" icon="icon-x" 
                                                    @change="addToBulkSelection(project_layer.layer_id, $event)">
                                                  </vs-checkbox>
                                                </vx-tooltip>
                                              </template>
                                            </vs-col>
                                          </template>
                                        </vs-row>
                                      </div>
                                    </vs-list-item>
                                  </template>
                                </vs-list>
                              </vs-collapse-item>

                            </vs-collapse>
                          </div>
                        </vs-row>
                      </vx-card>
                    </div>
                    
                </div>

                <!-- Ligne 3 -->
                <div class="vx-row" v-if="this.$store.state.portal.viewer_version === 'viewer'">
                    <!-- Tags (si can_project_tags_see) -->
                    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base"
                    v-if="adminByPass || current_user_project_caps.can_project_tags_see == '1'">
                        <vx-card
                            :title="$t('ProjectTags') + ' (' + this.projectTags.length + ')'"
                            title-color="#000"
                            content-color="#000"
                            card-background="white">

                            <!-- bouton d'édition si (si can_project_tags_manage) -->
                            <template v-if="adminByPass || this.current_user_project_caps.can_project_tags_manage == '1'" slot="actions">
                              <vs-button v-if="!tagsEditionInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="updateTagsStart()">{{ $t('Edit') }}</vs-button>
                              <div v-if="tagsEditionInProgress" class="btn-group-vertical">
                                <vs-button color="danger"  type="line" icon-pack="feather" icon="icon-x"    @click="updateTagsFinish()"> {{ $t('Finish') }}</vs-button>
                              </div>
                            </template>

                            <template v-if="this.projectTags.length > 0">
                              <vs-table 
                              class="tags_table"
                              :data="this.projectTags"
                              stripe
                              search 
                              pagination :max-items="this.tagsPagination" 
                              >
                              <!-- multiple v-model="this.selectedProjectTags"  -->

                                <template slot="thead">
                                  <vs-th sort-key="type"         >{{ $t('type') }}</vs-th>
                                  <vs-th sort-key="groupe"       >{{ $t('groupe') }}</vs-th>
                                  <vs-th sort-key="placeholder"  >{{ $t('placeholder') }}</vs-th>
                                  <vs-th sort-key="color"        >{{ $t('color') }}</vs-th>
                                  <vs-th sort-key="description"  >{{ $t('description') }}</vs-th>
                                  <vs-th sort-key="value"        >{{ $t('value') }}</vs-th>
                                  <vs-th>{{ $t('actions') }}</vs-th>
                                  <vs-th></vs-th>
                                </template>

                                <template slot-scope="{data}">
                                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                                    <!-- type -->
                                    <vs-td :data="tr.type">
                                      {{ $t(tr.type) }}
                                    </vs-td>
                                    <!-- groupe -->
                                    <vs-td :data="decodeURIComponent(tr.groupe)">
                                      {{ decodeURIComponent(tr.groupe) }}
                                      <template slot="edit" v-if="tagsEditionInProgress" >
                                        <vs-input @change="tagChanged($event, tr.tag_id, 'groupe')" v-model="tr.groupe" class="inputx" :placeholder="$t('groupe')" />
                                      </template>
                                    </vs-td>
                                    <!-- placeholder -->
                                    <vs-td :data="decodeURIComponent(tr.placeholder)">
                                      {{ decodeURIComponent(tr.placeholder) }}
                                      <template slot="edit" v-if="tagsEditionInProgress">
                                        <vs-input @change="tagChanged($event, tr.tag_id, 'placeholder')" v-model="tr.placeholder" class="inputx" :placeholder="$t('placeholder')" />
                                      </template>
                                    </vs-td>
                                    <!-- color -->
                                    <vs-td :data="tr.color" style="font-family: monospace;">
                                      <span :style="'color:'+tr.color">⬤</span>
                                      {{ tr.color }}
                                      <template slot="edit" v-if="tagsEditionInProgress">
                                        <input type="color" 
                                        @change="tagChanged($event, tr.tag_id, 'color')" 
                                        v-model="tr.color" 
                                        class="inputx" 
                                        :placeholder="$t('color')" 
                                        >
                                      </template>
                                    </vs-td>
                                    <!-- description -->
                                    <vs-td :data="decodeURIComponent(tr.description)">
                                      {{ decodeURIComponent(tr.description) }}
                                      <template slot="edit" v-if="tagsEditionInProgress">
                                        <vs-input @change="tagChanged($event, tr.tag_id, 'description')" v-model="tr.description" class="inputx" :placeholder="$t('description')" />
                                      </template>
                                    </vs-td>
                                    <!-- URL (si web ou realtime) -->
                                    <vs-td :data="tr.url" v-if="tr.type == 'web' || tr.type == 'realtime'" class="small-text" >
                                      {{ tr.url }}
                                      <template slot="edit" v-if="tagsEditionInProgress">
                                        <vs-input @change="tagChanged($event, tr.tag_id, 'url')" v-model="tr.url" class="inputx" :placeholder="$t('url')" />
                                      </template>
                                    </vs-td>

                                    <vs-td>
                                      
                                    </vs-td>
                                    <!-- Preview du media (uniquement si type == photo) -->
                                    <vs-td v-if="tr.type == 'photo' && tr.media_id !== '0'">
                                      <vx-tooltip :text="$t('mediaPreview')">
                                        <vs-button 
                                        color="primary" type="flat" icon-pack="feather" icon="icon-image"
                                        @click="mediaPreview(tr.media_id)"
                                        ></vs-button>
                                      </vx-tooltip>
                                    </vs-td>

                                    <!-- Retrait d'un tag (visible uniquement si user can_project_tags_manage) -->
                                    <vs-td v-if="adminByPass || current_user_project_caps.can_project_tags_manage == '1'">
                                      <vx-tooltip :text="$t('RemoveProjectTag')">
                                        <vs-button 
                                        :disabled="!tagsEditionInProgress"
                                        color="danger" type="flat" icon-pack="feather" icon="icon-trash"
                                        @click="removeProjectTagConfirm(tr.tag_id)"
                                        ></vs-button>
                                      </vx-tooltip>
                                    </vs-td>
                                  </vs-tr>
                                </template>
                              </vs-table>
                            </template>
                            <template v-else>
                              <vs-alert color="primary" :title="$t('noTagsInThisProject')" active="true">
                                <p v-if="adminByPass || current_user_project_caps.can_project_tags_add || current_user_project_caps.can_project_tags_manage">{{ $t('gotoModelToAddTags') }}</p>
                                <p v-else>{{ $t('askForRightsToAddTags') }}]</p>
                              </vs-alert>
                            </template>
                        </vx-card>
                    </div>
                </div>
               
                <!-- Ligne 4 -->
                <div class="vx-row">
                    <!-- Users (si can_project_users_see) -->
                    <div class="vx-col w-full lg:w-1/1 sm:w-1/1 mb-base"
                    v-if="adminByPass || current_user_project_caps.can_project_users_see == '1'">
                        <vx-card
                            id="usersCard"
                            :title="$t('ProjectUsers') + ' (' + this.projectUsers.length + ')'"
                            title-color="#000"
                            content-color="#000"
                            card-background="white">

                            <!-- bouton d'édition si can_project_users_manage -->
                            <template v-if="adminByPass || this.current_user_project_caps.can_project_users_manage == '1'" slot="actions">
                              <vs-button v-if="!usersEditionInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="updateUsersStart()">{{ $t('Edit') }}</vs-button>
                              <div v-if="usersEditionInProgress" class="btn-group-vertical">
                                <vs-button color="primary"  type="line" icon-pack="feather" icon="icon-plus" @click="openAddUserSidebar()"> {{ $t('addUser') }}</vs-button>
                                <vs-button color="danger"  type="line" icon-pack="feather" icon="icon-x"    @click="updateUsersFinish()"> {{ $t('Finish') }}</vs-button>
                              </div>
                            </template>


                            <!-- sidebar -->
                            <vs-sidebar class="sidebarx" position-right parent="#usersCard" :hidden-background="true" default-index="1" 
                            color="primary" v-model="usersSidebarVisible">
                              <div class="header-sidebar" slot="header">
                                <p>{{ $t('addUser') }}</p>
                              </div>
                              <!-- liste des users du portal -->
                              <vs-table 
                                v-model="portalUsersSelected"
                                search 
                                stripe 
                                :data="portalUsers">
                                <template slot="thead">
                                    <vs-th class="userAddColOne" sort-key="first_name" >{{ $t('userFirstName') }}</vs-th>
                                    <vs-th class="userAddColTwo" sort-key="last_name"  >{{ $t('userLastName') }}</vs-th>
                                </template>
                                <template slot-scope="{data}">
                                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                    <template v-if="data[indextr].user_id != 1">
                                      <vs-td class="userAddColOne" :data="data[indextr].first_name">
                                        {{ data[indextr].first_name | capitalize }}
                                      </vs-td>
                                      <vs-td class="userAddColTwo" :data="data[indextr].last_name">
                                        {{ data[indextr].last_name | capitalize }}
                                      </vs-td>
                                    </template>
                                  </vs-tr>
                                </template>
                              </vs-table>
                            </vs-sidebar>

                            <template v-if="this.projectUsers.length > 0">
                              <vs-table 
                              :data="this.projectUsers"
                              stripe
                              search 
                              pagination :max-items="this.usersPagination" 
                              >
                              <!-- multiple v-model="this.selectedProjectTags"  -->

                                <template slot="thead">
                                  <vs-th></vs-th>
                                  <vs-th v-if="multiPortalsProject" sort-key="portal_nicename" >{{ $t('Portal') }}</vs-th>
                                  <vs-th sort-key="first_name" >{{ $t('userFirstName') }}</vs-th>
                                  <vs-th sort-key="last_name"  >{{ $t('userLastName') }}</vs-th>
                                  <vs-th sort-key="can_project_access_start_date"  >{{ $t('can_project_access_start_date') }}</vs-th>
                                  <vs-th sort-key="can_project_access_end_date"  >{{ $t('can_project_access_end_date') }}</vs-th>
                                  <vs-th>{{ $t('ViewCapabilities') }}</vs-th>
                                  <vs-th>{{ $t('ContributionCapabilities') }}</vs-th>
                                  <vs-th>{{ $t('ManagementCapabilities') }}</vs-th>
                                  <vs-th>{{ $t('actions') }}</vs-th>
                                  <vs-th></vs-th>
                                </template>

                                <template slot-scope="{data}">
                                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                                    <template v-if="data[indextr].user_id != 1">
                                      <!-- avatar -->
                                      <vs-td class="p-0 mt-1 ml-2 align-middle">
                                        <user-avatar :user="tr" size="30px" :key="'avatar_'+tr.user_id" />                          
                                      </vs-td>
                                      <!-- portal_slug -->
                                      <vs-td v-if="multiPortalsProject" :data="tr.portal_nicename">
                                        {{ tr.portal_nicename }}                                
                                      </vs-td>
                                      <!-- first_name -->
                                      <vs-td :data="tr.first_name">
                                        {{ tr.first_name | capitalize }}                                
                                      </vs-td>
                                      <!-- last_name -->
                                      <vs-td :data="tr.last_name">
                                        {{ tr.last_name | capitalize }}
                                      </vs-td>
                                      <!-- can_project_access_start_date -->
                                      <vs-td class="caps flex flex-wrap">
                                        <template v-if="tr.user_id != $store.state.AppActiveUser.user_id">
                                          <vx-tooltip :text="$t('can_project_access_start_date')">
                                            <ul class="caps flex flex-wrap">
                                              <li class="cap">
                                                <input type="date" class="input_date"
                                                :disabled="!usersEditionInProgress" :v-model="project_access_start_dates[tr.user_id]" :value="project_access_start_dates[tr.user_id]" 
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_access_start_date', $event)" 
                                                >
                                              </li>
                                              <li class="cap">
                                                <vs-button 
                                                :disabled="!usersEditionInProgress" color="white" text-color="#EA5455" type="filled" icon-pack="feather" icon="icon-x" size="small"
                                                @click="set_ProjectUserCap(tr.user_id, 'can_project_access_start_date', '')"
                                                ></vs-button>
                                              </li>
                                            </ul>
                                          </vx-tooltip>
                                        </template>
                                        <template v-else> {{ project_access_start_dates[tr.user_id] ? project_access_start_dates[tr.user_id] : "-" }} </template>
                                      </vs-td>

                                      <!-- can_project_access_end_date -->
                                      <vs-td>
                                        <template v-if="tr.user_id != $store.state.AppActiveUser.user_id">
                                          <vx-tooltip :text="$t('can_project_access_end_date')">
                                            <ul class="caps">
                                              <li class="cap">
                                                <input type="date" class="input_date"
                                                :disabled="!usersEditionInProgress" :v-model="project_access_end_dates[tr.user_id]" :value="project_access_end_dates[tr.user_id]" 
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_access_end_date', $event)" 
                                                >
                                              </li>
                                              <li class="cap">
                                                <vs-button 
                                                :disabled="!usersEditionInProgress" color="white" text-color="#EA5455" type="filled" icon-pack="feather" icon="icon-x" size="small"
                                                @click="set_ProjectUserCap(tr.user_id, 'can_project_access_end_date', '')"
                                                ></vs-button>
                                              </li>
                                            </ul>
                                          </vx-tooltip>
                                        </template>
                                        <template v-else> {{ project_access_end_dates[tr.user_id] ? project_access_end_dates[tr.user_id] : "-" }} </template>
                                      </vs-td>

                                      <!-- Capacités de visualisation -->
                                      <vs-td>
                                        <ul class="caps">
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_model_see')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_model_see" 
                                                icon-pack="feather" icon="icon-eye"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_model_see', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_tools_use')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_tools_use" 
                                                icon-pack="feather" icon="icon-activity"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_tools_use', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_tags_see')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_tags_see" 
                                                icon-pack="feather" icon="icon-tag"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_tags_see', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>
                                          <li class="cap">
                                            <!-- si user !== current_user -->
                                            <template v-if="tr.user_id != $store.state.AppActiveUser.user_id">
                                              <vx-tooltip :text="$t('can_project_users_see')">
                                                <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_users_see" 
                                                  icon-pack="feather" icon="icon-user-check"
                                                  @change="set_ProjectUserCap(tr.user_id, 'can_project_users_see', $event)">
                                                </vs-checkbox>
                                              </vx-tooltip>
                                            </template>
                                            <template v-else>
                                              <vx-tooltip :text="$t('CanRemoveCapabilityYourself')">
                                                <vs-checkbox disabled="true" icon-pack="feather" icon="icon-user-check" :value="'1'===tr.can_project_users_see">
                                                </vs-checkbox>
                                              </vx-tooltip>
                                            </template>
                                          </li>
                                        </ul>
                                      </vs-td>

                                      <!-- Capacités de contribution -->
                                      <vs-td>
                                        <ul class="caps">
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_tags_add')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_tags_add" 
                                                icon-pack="feather" icon="icon-tag"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_tags_add', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_shares_send')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_shares_send" 
                                                icon-pack="feather" icon="icon-share-2"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_shares_send', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>
                                        </ul>
                                      </vs-td>

                                      <!-- Capacités d'administration -->
                                      <vs-td>
                                        <ul class="caps">
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_customize')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_customize" 
                                                icon-pack="feather" icon="icon-settings"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_customize', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>

                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_layers_manage')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_layers_manage" 
                                                icon-pack="feather" icon="icon-layers"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_layers_manage', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>
                                          <li class="cap">
                                            <vx-tooltip :text="$t('can_project_tags_manage')">
                                              <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_tags_manage" 
                                                icon-pack="feather" icon="icon-tag"
                                                @change="set_ProjectUserCap(tr.user_id, 'can_project_tags_manage', $event)">
                                              </vs-checkbox>
                                            </vx-tooltip>
                                          </li>                                        
                                          <li class="cap">
                                            <!-- si user !== current_user -->
                                            <template v-if="tr.user_id != $store.state.AppActiveUser.user_id">
                                              <vx-tooltip :text="$t('can_project_users_manage')">
                                                <vs-checkbox :disabled="!usersEditionInProgress" :value="'1'===tr.can_project_users_manage" 
                                                  icon-pack="feather" icon="icon-user-check"
                                                  @change="set_ProjectUserCap(tr.user_id, 'can_project_users_manage', $event)">
                                                </vs-checkbox>
                                              </vx-tooltip>
                                            </template>
                                            <template v-else>
                                              <vx-tooltip :text="$t('CanRemoveCapabilityYourself')">
                                                <vs-checkbox disabled="true" icon-pack="feather" icon="icon-user-check" :value="'1'===tr.can_project_users_manage">
                                                </vs-checkbox>
                                              </vx-tooltip>
                                            </template>
                                          </li>
                                        </ul>
                                      </vs-td>
        
                                      <!-- Retrait d'un user (visible uniquement si user can_project_users_manage et si user !== current_user) -->
                                      <vs-td v-if="adminByPass || current_user_project_caps.can_project_users_manage == '1'">
                                        <template v-if="tr.user_id != $store.state.AppActiveUser.user_id">
                                          <vx-tooltip :text="$t('RemoveProjectUser')">
                                            <vs-button 
                                            :disabled="!usersEditionInProgress"
                                            color="danger" type="flat" icon-pack="feather" icon="icon-user-x"
                                            @click="removeProjectUserConfirm(tr.user_id)"
                                            ></vs-button>
                                          </vx-tooltip>                                        
                                        </template>
                                        <template v-else>
                                          <vx-tooltip :text="$t('RemoveProjectCurrentUserImpossible')">
                                            <vs-button disabled="true" color="grey" type="flat" icon-pack="feather" icon="icon-user-x"></vs-button>
                                          </vx-tooltip>                                        
                                        </template>
                                      </vs-td>
                                    </template>
                                  </vs-tr>
                                </template>
                              </vs-table>
                            </template>
                        </vx-card>
                    </div>
                </div>
                <!-- IMAGE DE LEGENDE / SYSTEME DE COORDONNEES -->
                <div class="vx-row">
                
                  <!-- Légende -->
                  <div v-if="this.$store.state.projectsLegend" class="vx-col w-full mb-base" :class="[this.$store.state.projectsCoordinatesSystem ? 'lg:w-1/1 sm:w-1/2' : 'lg:w-1/1 sm:w-1/1' ]">
                      <vx-card
                      :title="$t('legend')"
                      title-color="#000"
                      content-color="#000"
                      card-background="white">
                      
                        <!-- bouton d'édition si user peut customize le project -->
                        <template v-if="adminByPass || (this.current_user_project_caps.can_project_customize === '1' && this.can_see_page)" slot="actions">
                          <vs-button v-if="!legendChangeInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="updateLegendStart()">{{ $t('Edit') }}</vs-button>
                          <div v-if="legendChangeInProgress" class="btn-group-vertical">
                            <vs-button color="success" type="line" icon-pack="feather" icon="icon-save" @click="updateLegendFinish()">   {{ $t('Finish')   }}</vs-button>
                          </div>
                        </template>
                          <vs-row vs-type="flex" vs-justify="center">
                            <!-- affichage de l'image -->
                            <vs-col v-if="!legendChangeInProgress" vs-type="flex" vs-justify="center" vs-align="center">
                              <img v-if="legendImgUrl !== '' && legendImageId !== '' && legendImageId !== '0'" :src="this.legendImgUrl" class="legend_img" alt="legend-img" height="300px">
                              <vs-alert v-else color="warning" active="true">{{ $t('noLegendImg') }}</vs-alert>
                            </vs-col>
                            <!-- upload d'une nouvelle image -->
                            <template v-else>
                              <div class="lg:w-3/5 sm:w-1/1">
                                <vs-upload
                                  automatic 
                                  fileName="file[]" 
                                  :action="`${this.$appConfig.imgBaseUrl}/upload.php?type=media`" 
                                  :text="$t('choseImage')"
                                  limit="1"
                                  multiple="false"
                                  accept=".jpg, .png"
                                  @on-success="legendUploadSuccess" 
                                  @on-error="legendUploadError" 
                                  @change="legendUploadStart"
                                />
                              </div>
                              <div class="lg:w-2/5 sm:w-1/1">
                                <vs-button type="line" color="danger"
                                @click="legendRemoveConfirm()">{{ $t('removeProjectLegend') }}</vs-button>
                              </div>
                            </template>
                          </vs-row>

                      </vx-card>
                  </div>

                  <!-- Système de coordonnées -->
                  <div v-if="this.$store.state.projectsCoordinatesSystem" class="vx-col w-full mb-base" :class="[this.$store.state.projectsLegend ? 'lg:w-1/1 sm:w-1/2' : 'lg:w-1/1 sm:w-1/1' ]">

                      <vx-card
                      :title="$t('coordinatesSystem')"
                      title-color="#000"
                      content-color="#000"
                      card-background="white">

                        <!-- bouton d'édition si user peut customize le project -->
                        <template v-if="adminByPass || (this.current_user_project_caps.can_project_customize === '1' && this.can_see_page)" slot="actions">
                          <vs-button v-if="!coordinatesSystemChangeInProgress" color="primary" type="line" icon-pack="feather" icon="icon-edit" @click="updateCoordinatesSytemStart()">{{ $t('Edit') }}</vs-button>
                          <div v-if="coordinatesSystemChangeInProgress" class="btn-group-vertical">
                            <vs-button color="success" type="line" icon-pack="feather" icon="icon-save" @click="updateCoordinatesSytemFinish()">   {{ $t('Finish')   }}</vs-button>
                          </div>
                        </template>

                        <div v-if="this.project.project_coordinate_system === '0' || this.project.project_coordinate_system === null">
                          <vs-alert color="warning" active="true">{{ $t('noCoordinatesSystem') }}</vs-alert>
                          <vs-alert class="mt-5" icon-pack="feather" icon="icon-globe" color="primary" active="true">{{ $t('coordinatesSystemEnablesGlobe') }}</vs-alert>
                          
                        </div>

                        <template v-if="this.project.project_coordinate_system !== '0'">
                          <template v-if="this.currentCoordinatesSystem[0] !== undefined">
                            <div class="vx-row m-1">
                              <vs-chip transparent color="primary">
                                <vs-avatar icon-pack="feather" icon="icon-map-pin" />
                                <span>EPSG {{ this.currentCoordinatesSystem[0].code }} | <strong>{{ this.currentCoordinatesSystem[0].name }}</strong></span>
                              </vs-chip>
                              <vs-button 
                              v-if="coordinatesSystemChangeInProgress"
                              :disabled="!coordinatesSystemChangeInProgress"
                              color="danger" type="flat" icon-pack="feather" icon="icon-x" @click="removeProjectCoordinatesSystemConfirm()"
                              ></vs-button>
                            </div>
                          </template>
                        </template>
                          
                        <template v-if="coordinatesSystemChangeInProgress">
                          <vs-table 
                          :key="this.project.project_coordinate_system"
                          v-model="coordinatesSystemSelected"
                          pagination max-items="10" search :data="coordinatesSystems">
                            <template slot="thead">
                              <vs-th sort-key="code">{{ $t('epsgCode') }}</vs-th>
                              <vs-th sort-key="name">{{ $t('epsgName') }}</vs-th>
                            </template>
                            <template slot-scope="{data}">
                              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                <vs-td :data="data[indextr].code">
                                  {{ data[indextr].code }}
                                </vs-td>
                                <vs-td :data="data[indextr].name">
                                  {{ data[indextr].name }}
                                </vs-td>
                              </vs-tr>
                            </template>
                          </vs-table>
                        </template>
                      </vx-card>
                  </div>
                </div>
                <!-- si manager du portal -->
                <template v-if="adminByPass || (canPortalManage && can_see_page)">

                  <vs-row vs-align="center" vs-type="flex" vs-justify="center" vs-w="12">
                    <vs-divider class="my-12"/>

                    <!-- dupliquer le projet --> 
                    <subscription-quotas/>
                    <template v-if="!multiPortalsProject && this.$store.state.subscriptionUsage.projectsAvailable > 0">
                      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
                        <vs-button type="line" icon-pack="feather" icon="icon-copy" @click="cloneProjectFormActive=true">{{ $t('cloneProject') }}</vs-button>
                        <vs-popup :title="$t('cloneProject')" :active.sync="cloneProjectFormActive">
                          <p>{{$t('cloneProjectMessage')}}</p>
                          <p class="mt-8"><strong>{{$t('cloneProjectChoice')}}</strong></p>
                          <vs-checkbox class="mt-4 px-4" v-model="cloneProjectLayers">{{$t('cloneProjectLayers')}}</vs-checkbox>
                          <vs-checkbox class="mt-4 px-4" v-model="cloneProjectUsers">{{$t('cloneProjectUsers')}}</vs-checkbox>
                          <vs-checkbox class="mt-4 px-4" v-model="cloneProjectSharedScene">{{$t('cloneProjectSharedScene')}}</vs-checkbox>
                          <div class="flex mb-4">
                            <vs-button class="w-full mt-12" @click="cloneProjectAction()">{{ $t('Confirm') }}</vs-button>
                          </div>
                        </vs-popup>

                      </vs-col>
                    </template>
                    <!-- gestion du statut public --> 
                    <template v-if="!multiPortalsProject">
                      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
                          <vs-button v-if="!isPublicModel" type="line" color="warning" icon-pack="feather" icon="icon-globe" 
                            @click="setProjectStatusPublicPrompt = true">
                            {{ $t('setProjectStatusPublic') }}
                          </vs-button>
                          <vs-button v-if="isPublicModel" type="line" color="warning" icon-pack="feather" icon="icon-lock" 
                            @click="setProjectStatus('private')">
                            {{ $t('setProjectStatusPrivate') }}
                          </vs-button>
                          <vs-prompt
                            :title= "this.$t('Confirm')"
                            :text= "this.$t('setProjectStatusPublicConfirm')"
                            color= "danger"
                            @accept="setProjectStatus('public')"
                            @cancel="setProjectStatusPublicPrompt = false"
                            :acceptText= "this.$t('Confirm')"
                            :cancelText= "this.$t('Cancel')"
                            :active.sync="setProjectStatusPublicPrompt"
                          />
                      </vs-col>
                    </template>
                    <!-- ajouter le projet à un autre portail (si pas public) -->
                    <template v-if="!isPublicModel">
                      <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
                          <vs-button color="warning" type="line" icon-pack="feather" icon="icon-share-2" @click="projectAddToOtherPortalConfirm()">{{ $t('projectAddToOtherPortal') }}</vs-button>
                          <vs-prompt
                          :title= "this.$t('Verification')"
                          color= "warning"
                          @accept="projectAddToOtherPortalAction"
                          :acceptText= "this.$t('Confirm')"
                          :cancelText= "this.$t('Cancel')"
                          :active.sync="projectAddToOtherPortalPrompt"
                          :is-valid="validProjectShareInputs">
                          <div class="con-exemple-prompt">
                            <span>{{$t('projectAddToOtherPortalTypeMessage')}}</span>
                            <!-- projectSharePortalSlugValid -->
                            <vs-input 
                              v-model="projectSharePortalSlug" 
                              :placeholder="this.$t('projectSharePortalSlug')"  
                              class="mt-3 w-full" 
                              :success="projectShareSlugSuccess" 
                              :danger="projectShareSlugError" 
                              :success-text="$t('validKey')"
                              :danger-text="$t('invalidKey')"
                              val-icon-success="icon-check"
                              val-icon-pack="feather"
                              val-icon-danger="icon-x"
                              @change="checkPortalSlug($event)"
                              />
                            <vs-input v-model="projectSharePortalUserId" :placeholder="this.$t('projectSharePortalUserId')"  class="mt-3 w-full" />
                            <vs-input 
                              v-model="projectAddToOtherKey" 
                              :placeholder="this.$t('projectAddToOtherKey')"  
                              class="mt-3 w-full" 
                              :success="projectShareKeySuccess" 
                              :danger="projectShareKeyError" 
                              :success-text="$t('validKey')"
                              :danger-text="$t('invalidKey')"
                              val-icon-success="icon-check"
                              val-icon-pack="feather"
                              val-icon-danger="icon-x"
                              />
                          </div>
                          </vs-prompt>
                      </vs-col>
                    </template> 
                    <!-- supprimer le projet --> 
                    <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-lg="3" vs-sm="4" vs-xs="12">
                        <vs-button v-if="multiPortalsProject" color="danger" type="line" icon-pack="feather" icon="icon-trash" @click="projectRemoveConfirm()">{{ $t('removeMultiPortalProject') }}</vs-button>
                        <vs-button v-else color="danger" type="line" icon-pack="feather" icon="icon-x" @click="projectRemoveConfirm()">{{ $t('removeProject') }}</vs-button>
                        <vs-prompt
                        :title= "this.$t('Verification')"
                        color= "danger"
                        @cancel="removeConfirmationValue=''"
                        @accept="projectRemoveAction"
                        :acceptText= "this.$t('Confirm')"
                        :cancelText= "this.$t('Cancel')"
                        :active.sync="projectRemoveActionPrompt">
                        <div class="con-exemple-prompt">
                          <span>{{$t('removeProjectTypeMessage')}} :<br><b>{{this.project.project_slug}}</b></span>
                        <vs-input 
                        v-model="removeConfirmationValue" 
                        class="mt-3 w-full" />
                        </div>
                        </vs-prompt>
                    </vs-col>
                  </vs-row>
                </template>
            </div>
            <!-- Si accès non autorisé -->
            <div v-else>
                <vs-alert color="warning" :title="$t('AccessDeniedTitle')" active="true">
                    <p>{{ $t(this.AccessDeniedMessage) }}</p>
                </vs-alert>
            </div>
        </div>
    </div>
</template>


<script>
import axios from '@/axios.js'
import moment from 'moment' // for date comparaison
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import md5 from 'crypto-js/md5'
import SubscriptionQuotas from './subscription/quotas.vue'
import logActivity from '@/logActivity.js'
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css'
import ColorPicker from 'v-color'
import 'v-color/dist/index.css'

export default {
  components: {
    SubscriptionQuotas, VueSimpleSuggest, ColorPicker
  },

  data () {
    return {
      // ROYBON / Statut public
      setProjectStatusPublicPrompt: false,

      // ROYBON / Keywords
      newKeywordFormActive: false,
      newKeyword: '',
      newKeywordsList: [],

      // ROYBON / Liste des portals ayant accès à ce projet
      projectPortals: {},
      multiPortalsProject: null,

      // ROYBON / Confirmation de suppression du projet
      removeConfirmationValue:'',
      projectRemoveActionPrompt:false,

      // ROYBON / Confirmation de l'ajout du projet à un autre portal
      projectAddToOtherPortalPrompt:false,
      projectSharePortalSlug:'',
      projectSharePortalId:null,
      projectSharePortalSlugValid:null,
      projectSharePortalUserId:'',
      projectAddToOtherKey:'',
      expectedCheck:'',
      thirdPartyPortalId:'',

      // ROYBON / Légende
      legendChangeInProgress: false,
      legendImgUrl: '',
      legendImageUploading: false,
      legendEditionInProgress: false,
      legendImageId: '',

      // ROYBON / Géolocalisation
      coordinatesSystems: [],
      currentCoordinatesSystem: [],
      coordinatesSystemSelected: [],
      coordinatesSystemChangeInProgress: false,

      // ROYBON / Variables locales relatives au projet affiché
      project_slug:  '',
      project:{},
      projectKeywords:{},

      // ROYBON / Modification des infos du projet
      new_project_slug: '',
      new_project_nicename: '',

      // ROYBON / Project cover image
      thumbName : 'thumb_2000_500.jpg',
      projectCoverImageKey: 1,
      projectInfoEditionInProgress: false,
      coverImageUploading: false,

      // ROYBON / Localisation du projet
      project_lat: '',
      project_lng: '',

      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getProjectBySlug: 'getProjectBySlug',
      getUserProjectCaps: 'getUserProjectCaps',
      setProjectLocation: 'setProjectLocation',
      getProjectLayers: 'getProjectLayers',
      getProjectLayersWithDetails: 'getProjectLayersWithDetails',
      getProjectLayerParameter: 'getProjectLayerParameter',
      getProjectLayersParameters: 'getProjectLayersParameters',
      setProjectLayerParameter: 'setProjectLayerParameter',
      deleteProjectLayerAllocation: 'deleteProjectLayerAllocation',
      deleteProjectLayer4dPlanification: 'deleteProjectLayer4dPlanification',
      // getProjectTags: 'getProjectTags',
      getProjectUsers: 'getProjectUsers',
      getMediaUrl: 'getMediaUrl',
      deleteProjectTag: 'deleteProjectTag',
      updateProjectTag: 'updateProjectTag',
      setProjectUserCap: 'setProjectUserCap',
      deleteProjectUser: 'deleteProjectUser',
      updateProjectSlug: 'updateProjectSlug',
      updateProjectNicename: 'updateProjectNicename',
      getPortalUsedLayers: 'getPortalUsedLayers',
      getPortalUnusedLayers: 'getPortalUnusedLayers',
      createProjectLayerAllocation: 'createProjectLayerAllocation',
      getCoordinatesSystems: 'getCoordinatesSystems',
      getCoordinatesSystemsFromQuery: 'getCoordinatesSystemsFromQuery',
      getCoordinatesSystemFromId: 'getCoordinatesSystemFromId',
      updateProjectCoordinatesSystem: 'updateProjectCoordinatesSystem',
      updateProjectLegend: 'updateProjectLegend',
      removePortalProjectAllocation: 'removePortalProjectAllocation',
      getProjectPortals: 'getProjectPortals',
      addPortalProject: 'addPortalProject',
      addProjectUser: 'addProjectUser',
      getPortalBySlug: 'getPortalBySlug',
      getPortalUsers: 'getPortalUsers',
      clonePortalProject: 'clonePortalProject',
      getProjectAccessRequests: 'getProjectAccessRequests',
      GenerateRdpFile: 'GenerateRdpFile',

      // ROYBON / Demandes d'accès 
      accessRequests: null,

      // ROYBON / Formulaire de duplication du projet
      cloneProjectFormActive: false,
      cloneProjectLayers: true,
      cloneProjectUsers: true,
      cloneProjectSharedScene: true,

      // ROYBON / Droits du user stockés localement
      current_user_global_caps: this.$store.state.current_user_global_caps,
      current_user_portal_caps: this.$store.state.current_user_portal_caps,
      current_user_project_caps: {
        can_project_access: 0,
        can_project_access_end_date: null,
        can_project_access_start_date: null,
        can_project_chat_moderate: 0,
        can_project_chat_read: 0,
        can_project_chat_write: 0,
        can_project_customize: 0,
        can_project_layers_manage: 0,
        can_project_model_see: 0,
        can_project_shares_manage: 0,
        can_project_shares_see: 0,
        can_project_shares_send: 0,
        can_project_tags_add: 0,
        can_project_tags_manage: 0,
        can_project_tags_see: 0,
        can_project_tools_use: 0,
        can_project_users_manage: 0,
        can_project_users_see: 0
      },

      // ROYBON / Interprétation des droits
      can_see_page: null,
      AccessDeniedMessage: '',
      AccessDeniedInfos: '',
      adminByPass: false,

      // ROYBON / Layers edition
      projectLayersVersion:1,
      layerToAdd:'',
      projectLayers:{},
      projectLayersLoaded:false,
      layersParameters: {},
      pointcloudStyles: ['RGB', 'HEIGHT', 'INTENSITY'],
      incrementedLayersKey: 1,
      DisplayColorPicker: {},
      ForcedColors: {},
      PixelSizes: {},
      Min4D: {},
      Max4D: {},
      LayerToRemove: '',
      layersCardHeight: 0,
      layersDaoConverterStyles: [],
      layersDaoDbStyles: null,
      layersDaoDbStylesLoaded: false,

      // ROYBON / Shapefiles attributes
      shpFillColors: {},
      shpOutlineColors: {},
      shpPointColors: {},

    
      // ROYBON / Users addition
      usersSidebarVisible: false,
      portalUsers: [],
      portalUsersSelected: [],
      userToAdd: '',

      // ROYBON / Layers addition
      layersSidebarVisible: false,
      portalUsedLayers: {},
      portalUnusedLayers: {},

      // ROYBON / Users
      projectUsers:{},
      userToRemove: '',
      usersEditionInProgress: false,
      usersPagination: '25',
      project_access_start_dates: {},
      project_access_end_dates: {},

      // ROYBON / Tags
      projectTags:{},
      tagsPagination: '10',
      selectedProjectTags:[],
      popupImageActive: false,
      popupImageUrl: '',
      TagToRemove: '',

      // ROYBON / Etat de chargement et des actions en cours sur la page
      loaded: false,
      markerEditionInProgress: false,
      markerMoved: false,
      incrementedMapKey: 1,
      layersManageInProgress: false,
      tagsEditionInProgress: false,


      // ROYBON / Google Maps
      map_style : {
        width: '100%',
        height: '25vh',
        minHeight: '200px',
        maxHeight: '500px'
      },
      eiffel_lat: 48.858053,
      eiffel_lng:  2.294289,
      jdata:{},
      center: {}, // défini par initGoogleMap()
      infoContent: '',
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: { width: 0, height: -35 }
      },
      markers: [
        { position:
        {}, // définie par initGoogleMap()
        infoText: ''}
      ], 
      place: null,
      latLng: {}, 

      // ROYBON / RDP
      rdpPopupActive: false,
      rdpPassword: '',
      rdpContent: '',

      bulkEditSelection: []
    }
  },
  methods: {
    addToBulkSelection (layerId, event) {
      if (typeof event.target != 'undefined' && event.target.type === 'checkbox') {
        const value = event.target.checked
        if (value && !this.bulkEditSelection.includes(layerId)) {
          this.bulkEditSelection.push(layerId)
        } else if (!value && this.bulkEditSelection.includes(layerId)) {
          this.bulkEditSelection = this.bulkEditSelection.filter(el => el != layerId)
        }
      }
    },
    displayFeature (dataType) {
      const isBetaFeature = this.isBetaFeature(dataType)
      if (isBetaFeature) {
        return this.$store.state.betaTester == true
      } else {
        return true
      }
    },
    isBetaFeature (dataType) {
      const format = Object.entries(this.$store.state.layer_types_v14)
        .map(item => item[1])
        .filter(item => item.slug == dataType)
      const isBetaFeature = format[0].beta
      return isBetaFeature
    },
    daoLayerHtmlTitle (dxfLayer) {
      if (dxfLayer.layer_visible == '1') {
        return `👁 ${dxfLayer.layer_name}`
      } else {
        return `🛇 ${dxfLayer.layer_name}`
      }
    },
    shpLayerStyle (project_layer) {
      const outlined = project_layer.shp_polygon_outlined == '1'
      const filled = project_layer.shp_polygon_filled == '1'
      const color = project_layer.shp_point_color || '#1739b57a'
      const borderColor = project_layer.shp_polygon_outline_color || '#1739b57a'
      const backgroundColor = project_layer.shp_polygon_fill_color || '#1739b57a'
      const borderWidth = project_layer.shp_polygon_outline_width || '1'
      return {
        color: color || '#fff',
        borderColor: outlined ? borderColor : 'none', 
        borderWidth: `${outlined ? borderWidth : 0}px`,
        backgroundColor: filled ? backgroundColor : 'none',
        borderStyle: 'solid'
      }
    },
    gotoProjectAccessRequest (request_hash) {
      const redirect = `/project_access_request/${request_hash}`
      this.$router.push(redirect).catch(() => {})
    },
    // ROYBON / Récupère la liste des keywords utilisés sur le portal
    getPortalKeywords () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/getPortalKeywords?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.newKeywordsList = response.data
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Visite d'un keyword
    gotoKeyword (keyword) {
      const redirect = `/${this.$store.state.portal_slug}/projects/?keyword=${keyword}`
      this.$router.push(redirect).catch(() => {})
    },
    // ROYBON / Retrait d'un keyword
    removeKeyword (keyword) {
      this.projectKeywords.splice(this.projectKeywords.indexOf(keyword), 1)
      this.setProjectKeywords()
    },
    // ROYBON / Sanitize de keyword
    newKeywordSanitize () {
      this.newKeyword = this.urlify(this.newKeyword)
    },
    // ROYBON / Ajout de keyword
    newKeywordFormValidate () {
      this.newKeywordSanitize()
      this.projectKeywords.push(this.newKeyword)
      this.setProjectKeywords()
    },
    // ROYBON / Définit la liste des keywords pour le projet
    setProjectKeywords () {
      //const params = `project_id=${this.project.project_id}&keywords=${JSON.stringify(this.projectKeywords)}`
      const params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('keywords', `${JSON.stringify(this.projectKeywords)}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/setProjectKeywords`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed')
            })
            // log l'activité du user
            logActivity.add('project_keyword_add', this.newKeyword, null, null, this.project.project_id)
            // refresh
            this.getProjectInfos()
            this.newKeyword = ''
            this.newKeywordFormActive = false
          } else {
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
            this.projectKeywords = JSON.parse(this.project.keywords)
          }
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Login
    login () {
      sessionStorage.setItem('loginRedirect', this.$route.fullPath)
      this.$auth.login({ target: this.$route.fullPath })
    },
    // ROYBON / Changement de statut public
    setProjectStatus (status) {
      //const params = `project_id=${this.project.project_id}&project_status=${status}`
      const params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('project_status', `${status}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/setProjectStatus`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed')
            })
            this.getProjectInfos()
          } else {
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          }
          // log l'activité du user
          logActivity.add('project_clone', response.data[0], null, null, this.project.project_id)
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Action de duplication du projet
    cloneProjectAction () {
      this.cloneProjectFormActive = false
      if (this.$store.state.subscriptionUsage.projectsAvailable > 0) {
        //const params = `current_user_id=${this.$store.state.AppActiveUser.user_id}&portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&clone_layers=${this.cloneProjectLayers}&clone_users=${this.cloneProjectUsers}&clone_shared_scene=${this.cloneProjectSharedScene}`
        const params = new FormData()
        params.set('current_user_id', `${this.$store.state.AppActiveUser.user_id}`)
        params.set('portal_id', `${this.$store.state.portal_id}`)
        params.set('project_id', `${this.project.project_id}`)
        params.set('clone_layers', `${this.cloneProjectUsers}`)
        params.set('clone_users', `${this.cloneProjectUsers}`)
        params.set('clone_shared_scene', `${this.cloneProjectSharedScene}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.clonePortalProject}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (!isNaN(response.data)) {
              this.$vs.notify({
                color:'success',
                title: this.$t('cloneProjectSuccess')
              })
              this.$router.push({ path: `/${this.$store.state.portal_slug}/projects`})
            } else {
              this.$vs.notify({
                color:'danger',
                title: this.$t('cloneProjectError')
              })
            }
            // log l'activité du user
            logActivity.add('project_clone', response.data[0], null, null, this.project.project_id)
          })
          .catch((error)   => { console.log(error) })
      } else {
        this.$vs.notify({
          color:'danger',
          title: `${this.$t('cloneProjectError')  }. ${  this.$t('subscriptionProjectsExceeded')}`
        })
      }
    },

    // ROYBON / Liste les portals ayant accès à ce project
    projectPortalsList () {
      const params = `project_id=${this.project.project_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getProjectPortals}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.projectPortals = response.data
        })
        .catch((error)   => { console.log(error) })
    },
    // -------------------------------------------------------------
    // ROYBON / Confirmation de la suppression du projet
    projectRemoveConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.multiPortalsProject ? this.$t('projectMultiPortalRemoveConfirmationMessage') : this.$t('projectRemoveConfirmationMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.projectRemoveInputSlug
      })
    },
    // ROYBON /Ouverture du formulaire de suppression du projet
    projectRemoveInputSlug () {
      this.projectRemoveActionPrompt = true
    },
    // ROYBON / Action de suppression du projet
    projectRemoveAction () {
      // controle la valeur de vérfication
      if (this.removeConfirmationValue !== this.project.project_slug) {
        this.$vs.notify({
          color:'danger',
          title:this.$t('valueIsNotValid'),
          text: this.multiPortalsProject ? this.$t('projectMultiPortalDeletionCanceled') : this.$t('projectDeletionCanceled')
        })
      } else {
        // Met à jour la BDD du projet
        //const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}`
        const params = new FormData()
        params.set('portal_id', `${this.$store.state.portal_id}`)
        params.set('project_id', `${this.project.project_id}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.removePortalProjectAllocation}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              this.$vs.notify({
                color:'success',
                title: this.multiPortalsProject ? this.$t('projectMultiPortalDeletionSuccess') : this.$t('projectDeletionSuccess')
              })
              this.$router.push({ path: `/${this.$store.state.portal_slug}/projects`})
            } else {
              this.$vs.notify({
                color:'danger',
                title: this.multiPortalsProject ? this.$t('projectMultiPortalDeletionError') : this.$t('projectDeletionError')
              })
            }
            // log l'activité du user
            logActivity.add('project_portal_removal', response.data[0], null, null, this.project.project_id)
          })
          .catch((error)   => { console.log(error) })
      }
    },
    
    // -------------------------------------------------------------
    // ROYBON / Confirmation du partage du projet sur un autre portal
    projectAddToOtherPortalConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `${this.$t('readThisVeryCarefully')} - 1/2`,
        text: this.$t('projectAddToOtherPortalConfirmationMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.projectAddToOtherPortalConfirm2
      })
    },
    projectAddToOtherPortalConfirm2 () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `${this.$t('readThisVeryCarefully')} - 2/2`,
        text: this.$t('projectAddToOtherPortalConfirmationMessage2'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.projectAddToOtherPortalInputs
      })
    },
    // ROYBON / Ouverture du formulaire de partage du projet sur un autre portal
    projectAddToOtherPortalInputs () {
      this.projectAddToOtherPortalPrompt = true
    },
    // ROYBON / Lance la vérif du slug saisi
    checkPortalSlug (e) {
      this.portalSlugExists(e.target.value)
    },
    // ROYBON / Check l'existence d'un portal via son slug
    portalSlugExists () {
      const params = `portal_slug=${this.projectSharePortalSlug}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/public/getPortalBySlug?${params}`
      this.projectSharePortalSlugValid = false 
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (null !== response) {
            if (response.data != null && response.data != 'null') {
              if (!isNaN(response.data.portal_id)) {
                this.projectSharePortalSlugValid = true 
                this.projectSharePortalId = response.data.portal_id
                return true
              }
            }
          }
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Action de partage du projet sur un autre portal
    projectAddToOtherPortalAction () {
      if (this.projectAddToOtherKey.toString() !== this.expectedCheck.toString()) {
        // controle la valeur de vérification
        this.$vs.notify({
          color:'danger',
          title:this.$t('valueIsNotValid'),
          text: this.$t('projectAddToOtherPortalCanceled')
        })
      } else {
        let params = ''
        let rimnat_api_call_url = ''
        // Ajoute l'allocation du projet au portal demandé
        //params = `portal_id=${this.projectSharePortalId}&project_id=${this.project.project_id}`
        params = new FormData()
        params.set('portal_id', `${this.projectSharePortalId}`)
        params.set('project_id', `${this.project.project_id}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.addPortalProject}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 

            if (response.data[0] === 'ok' || response.data == 'ok') {
              this.$vs.notify({
                color:'success',
                title: this.$t('projectAddToOtherPortalSuccess')
              })
              // log l'activité du user
              logActivity.add('project_multiportal_share', this.projectSharePortalId, null, null, this.project.project_id)
              logActivity.add('project_multiportal_import', 'success', this.projectSharePortalUserId, this.projectSharePortalId, this.project.project_id)

              // Ajoute le user tiers comme utilisateur basique
              //params = `portal_id=${this.projectSharePortalId}&project_id=${this.project.project_id}&user_id=${this.projectSharePortalUserId}`
              params = new FormData()
              params.set('portal_id', `${this.projectSharePortalId}`)
              params.set('project_id', `${this.project.project_id}`)
              params.set('user_id', `${this.projectSharePortalUserId}`)
              rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.addProjectUser}`
              axios.post(rimnat_api_call_url, params)
                .then((response) => { 
                  if (response.data[0] === 'ok' || response.data == 'ok') {
                    this.$vs.notify({
                      color:'success',
                      title: this.$t('projectAddUserSuccess')
                    })
                    this.projectPortalsList() 
                  } else if (response.data[0] === 'exists' || response.data == 'exists') {
                    this.$vs.notify({
                      color:'warning',
                      title: this.$t('projectUserExists')
                    })
                  } else {
                    this.$vs.notify({
                      color:'danger',
                      title: this.$t('projectAddUserError')
                    })
                  }
                  // log l'activité du user
                  logActivity.add('project_user_allocation', 'success', null, null, this.project.project_id)
                  logActivity.add('project_user_allocation', 'success', this.projectSharePortalUserId, this.projectSharePortalId, this.project.project_id)
                })
                .catch((error)   => { console.log(error) })

            } else {
              this.$vs.notify({
                color:'danger',
                title: this.$t('projectAddToOtherPortalError')
              })
            }
          })
          .catch((error)   => { console.log(error) })
      }
    },

    // -----------------------------------------
    // ROYBON / Début de l'envoi d'une image
    legendUploadStart () {    
      this.coverImageUploading = true
    },
    // ROYBON / Upload réussi d'une image : actualisation du legend image id
    legendUploadSuccess (e) {
      if (!isNaN(e.target.response)) {
        this.legendUpdate(e.target.response)
      }
      this.coverImageUploading = false
      this.updateLegendFinish()
    },

    // ROYBON / Update de la légende du projet
    legendUpdate (media_id) {
      // Met à jour la BDD du projet
      //const params = `project_id=${this.project.project_id}&media_id=${media_id}`
      const params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('media_id', `${media_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.updateProjectLegend}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // obtient l'URL de la nouvelle image uploadée et actualise le render
            this.getLegendImage(media_id)
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed')
            })
          } else {
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Confirmation de retrait de la légende du projet
    legendRemoveConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('legendRemoveConfirmMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.legendRemoveAction
      })
    },
    // ROYBON / Retrait de la légende en BDD du projet
    legendRemoveAction () {
      this.legendUpdate('0')
    },

    // ROYBON / Upload échoué d'une image
    legendUploadError () {
      this.coverImageUploading = false
      this.legendEditionInProgress = false
      this.$vs.notify({
        color: 'danger',
        title: this.$t('UploadError')
      })
    },
    // ROYBON / Début de modification de la légende
    updateLegendStart () {
      this.legendChangeInProgress = true
    },
    // ROYBON / Fin de changement de la légende
    updateLegendFinish () {
      this.legendChangeInProgress = false
      logActivity.add('project_legend_update', 'success', null, null, this.project.project_id)
    },
    // ROYBON / Obtient l'URL de l'image de la légende
    getLegendImage (media_id) {
      if (media_id !== '0') {
        const params = `media_id=${media_id}`
        const rimnat_api_call_url = `${this.api_server_baseurl}/media/${this.getMediaUrl}?${params}`
        axios.get(rimnat_api_call_url, {
        })
          .then((response) => { 
            if (response.data !== null && response.data !== '0' && response.data != null && response.data != 'null') {
              this.legendImgUrl = response.data
              this.legendImageId = media_id     
            }
          })
          .catch((error)   => { console.log(error) })
      } else {
        this.legendImgUrl = ''
        this.legendImageId = ''    
      }
    },

    // -----------------
    // ROYBON / Début de changement de coordinates system
    updateCoordinatesSytemStart () {
      this.coordinatesSystemChangeInProgress = true
    },
    // ROYBON / Fin de changement de coordinates system
    updateCoordinatesSytemFinish () {
      this.coordinatesSystemChangeInProgress = false
    },

    // ROYBON / Confimation du changement de coordinates system
    updateCoordinatesSystemConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: `${this.$t('updateCoordinatesSystemConfirmationMessage')} : EPSG:${this.coordinatesSystemSelected.code} | ${this.coordinatesSystemSelected.name}`,
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.updateCoordinatesSystemAction
      })
    },
    // ROYBON / Action de changement de coordinates system
    updateCoordinatesSystemAction () {
      this.coordinatesSystemChangeInProgress = true
      const coordinatesSystemSelectedId = this.coordinatesSystemSelected.id
      //const params = `project_id=${this.project.project_id}&value=${coordinatesSystemSelectedId}`
      const params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('value', `${coordinatesSystemSelectedId}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.updateProjectCoordinatesSystem}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            this.getProjectInfos()
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed')
            })
            this.coordinatesSystemChangeInProgress = false
            logActivity.add('project_coordinates_system_update', 'success', null, null, this.project.project_id)
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
            this.coordinatesSystemChangeInProgress = false
          }
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          this.$vs.notify({
            color:'danger',
            title:this.$t('UpdateError'),
            text:this.$t('ParameterNotUpdated')
          })
          this.coordinatesSystemChangeInProgress = false
        })
    },

    
    // ROYBON / Confimation du retrait du coordinates system 
    removeProjectCoordinatesSystemConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('removeCoordinatesSystemConfirmationMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.removeProjectCoordinatesSystemAction
      })
    },
    
    // ROYBON / Action du retrait du coordinates system 
    removeProjectCoordinatesSystemAction () {
      this.coordinatesSystemSelected.id = '0'
      this.updateCoordinatesSystemAction()
      logActivity.add('project_coordinates_system_removal', 'success', null, null, this.project.project_id)
    },

    // ROYBON / Obtient l'objet coordinates system en se basant sur l'id
    getCurrentCoordinatesSystem (id) {
      const params = `value=${id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/coordinate/${this.getCoordinatesSystemFromId}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data !== null && response.data != null && response.data != null && response.data != 'null') {
            this.currentCoordinatesSystem = response.data
          } else {
            this.currentCoordinatesSystem.code = ''
            this.currentCoordinatesSystem.name = ''
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Cherche les systemes de coordonnées répondant à la recherche
    buildCoordinatesSystemsList () {
      const params = ''
      const rimnat_api_call_url = `${this.api_server_baseurl}/coordinate/${this.getCoordinatesSystems}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != null && response.data != 'null') this.coordinatesSystems = response.data
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Début de l'édition des infos du projet
    getProjectInfostart () {
      this.projectInfoEditionInProgress = true     
      this.new_project_nicename = this.project.project_nicename
      this.new_project_slug = this.project.project_slug 
      // notification
      this.$vs.notify({
        color:'grey',
        title:this.$t('EditionStarted'),
        text:this.$t('PressSaveToApply')
      })
    },
    // ROYBON / Début d'édition des infos du projet
    updateProjectInfoFinish () {
      this.projectInfoEditionInProgress = false     

      // Détecte si changement de slug
      if (this.new_project_slug !== this.project.project_slug) {
        this.updateProjectSlugConfirm()
      }
      // Détecte si changement de nicename
      if (this.new_project_nicename !== this.project.project_nicename) {
        this.updateProjectNicenameAction()
      }
      // notification
      this.$vs.notify({
        color:'success',
        title:this.$t('EditionFinished')
      })
    },
    // ROYBON / Changement du slug du projet 
    projectSlugChange (e) {
      // sanitize
      let new_project_slug = e.target.innerText
      new_project_slug = this.urlify(new_project_slug)
      this.new_project_slug = new_project_slug
      e.target.innerText = new_project_slug
      // check avalibility
      const slug_hash = md5(sha1(new_project_slug).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `project_slug=${new_project_slug}&slug_hash=${slug_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getProjectBySlug}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          // project exists
          if (response.data !== null && response.data != 'null') {
            // and it's not the current project
            if (response.data.project_id !== this.project.project_id) {
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('projectSlugUnavailable')
              })
              this.new_project_slug = this.project.project_slug
              e.target.innerText = this.project.project_slug
            }
          } else {
            this.$vs.notify({
              color:'success',
              title:`${new_project_slug} : ${this.$t('projectSlugAvailable')}`,
              text:this.$t('youCanSaveThisSlug')
            })
            this.new_project_slug = new_project_slug
            e.target.innerText = new_project_slug
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Fonction de formattage de string pour URL
    urlify (value) {
      if (!value) return ''
      value = value.toString()
      value = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
      value = value.replace(/[^0-9a-z]/gi, '-')
      value = value.replace(/ /g, '-')
      value = value.split('--').join('-')
      value = value.replace(/-/gi, ' ')
      value = value.trim()
      value = value.replace(/ /gi, '-')
      value = value.toLowerCase()
      value = value.split('--').join('-')
      value = value.substring(0, 15)
      return value
    },

    // ROYBON / Fonction de nettoyage du titre
    sanitizeProjectTitle (value) {
      const regex = /(<([^>]+)>)/ig
      value = value.replace(regex, '')
      value = value.trim()
      return value
    },

    // ROYBON / Changement du nicename du projet 
    projectNicenameChange (e) {
      let new_project_nicename = e.target.innerText
      new_project_nicename = this.sanitizeProjectTitle(new_project_nicename)
      this.new_project_nicename = new_project_nicename
      e.target.innerText = new_project_nicename
    },

    // ROYBON / Confimation du changement de slug
    updateProjectSlugConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('Confirm'),
        text: this.$t('projectSlugEditMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.updateProjectSlugAction
      })
    },
    // ROYBON / Application du changement de slug
    updateProjectSlugAction () {
      //const params = `project_id=${this.project.project_id}&value=${this.new_project_slug}`
      const params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('value', `${this.new_project_slug}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.updateProjectSlug}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            this.$router.push({ path: `/project/${this.new_project_slug}`})
            this.project_slug = this.new_project_slug
            this.getProjectInfos()
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed')
            })
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          }
          logActivity.add('project_slug_update', response.data[0], null, null, this.project.project_id)

        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('UpdateError'),
            text:this.$t('ParameterNotUpdated')
          })
        })
    },
    // ROYBON / Application du changement de nicename
    updateProjectNicenameAction () {
      //const params = `project_id=${this.project.project_id}&value=${this.new_project_nicename}`
      const params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('value', `${this.new_project_nicename}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.updateProjectNicename}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            this.project_nicename = this.new_project_nicename
            this.getProjectInfos()
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed')
            })
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          }
          logActivity.add('project_nicename_update', response.data[0], null, null, this.project.project_id)
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('UpdateError'),
            text:this.$t('ParameterNotUpdated')
          })
        })
    },

    // ROYBON / Début de l'envoi d'une image
    coverUploadStart () {    
      this.coverImageUploading = true
    },
    // ROYBON / Upload réussi d'une image
    coverUploadSuccess () {
      this.projectCoverImageKey = this.projectCoverImageKey + 1      
      this.coverImageUploading = false
      this.updateProjectInfoFinish()
      logActivity.add('project_cover_update', 'success', null, null, this.project.project_id)
    },
    // ROYBON / Upload réchoué d'une image
    coverUploadError () {
      this.coverImageUploading = false
      this.projectInfoEditionInProgress = false
      this.$vs.notify({
        color: 'danger',
        title: this.$t('UploadError')
      })
    },


    // ROYBON / Charge les tags du project
    // buildProjectTagsList () {
    //   const params = `project_id=${this.project.project_id}`
    //   const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.getProjectTags}?${params}`
    //   axios.get(rimnat_api_call_url, {
    //   })
    //     .then((response) => { 
    //       if (response.data != null && response.data != 'null' && typeof response.data == 'object') {
    //         this.projectTags = response.data
    //         for (const key of Object.keys(this.projectTags)) {
    //           const tag = this.projectTags[key]
    //           tag.url = decodeURIComponent(tag.url)
    //           tag.placeholder = decodeURIComponent(tag.placeholder)
    //           tag.groupe = decodeURIComponent(tag.groupe)
    //           tag.description = decodeURIComponent(tag.description)
    //         }
    //       }
    //     })
    //     .catch((error)   => { console.log(error) })
    // },

    // ROYBON / Charge les users du project
    buildProjectUsersList () {
      const project_hash = md5(sha1(this.project.project_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&project_hash=${project_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.getProjectUsers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            if ((typeof response.data) == 'object') {
              this.projectUsers = response.data
            }
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Charge les layers du project
    buildProjectLayerList () {
      const project_hash = md5(sha1(this.project.project_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `project_id=${this.project.project_id}&project_hash=${project_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.getProjectLayersWithDetails}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.projectLayers = response.data
          this.projectLayersLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },

    forceMapRerender () {
      this.incrementedMapKey += 1
    },
    forceLayersRerender () {
      this.incrementedLayersKey += 1
    },

    // ROYBON / Image par défaut si 404
    imageUrlAlt (event) {
      event.target.src = `${this.$appConfig.imgBaseUrl}/project/cover/undefined/${this.thumbName}`
    },
    
    // ROYBON / Check si l'affichage de la liste des groupes est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      
      // bypass pour super admin et admin
      if (typeof gc === 'undefined' || typeof poc === 'undefined') {
        this.can_see_page = true

      } else if (this.isPublicModel) {
        this.can_see_page = true
        this.loaded = true

      } else if (gc.can_global_manage === '1' || this.canPortalManage) {
        this.adminByPass = true
        this.can_see_page = true

      } else if (poc.can_portal_manage === '1' || poc.can_portal_projects_manage === '1') {
        this.adminByPass = true
        this.can_see_page = true

      } else if (this.current_user_project_caps === null) {
        this.can_see_page = false
        this.AccessDeniedMessage = 'ProjectAccessDeniedFull' 
        this.AccessDeniedInfos = 'project access invalid'   
        this.loaded = true
      } else {
        const prc =               this.current_user_project_caps
        const poc_start_date =    poc.can_portal_access_start_date
        const poc_end_date =      poc.can_portal_access_end_date
        const prc_start_date =    prc.can_project_access_start_date
        const prc_end_date =      prc.can_project_access_end_date

        // ROYBON / Limites à l'échelle globale ou du portal
        if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
          if (moment().isSameOrBefore(poc_start_date)) {
            this.can_see_page = false
            this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
            this.AccessDeniedInfos = poc_start_date
          } else if (moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
            this.can_see_page = false
            this.AccessDeniedMessage = 'PortalAccessExpired'
            this.AccessDeniedInfos = poc_end_date
          } else {
            this.can_see_page = true
          }
        } else {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
          this.AccessDeniedInfos = 'global or portal access invalid'
        }
        // ROYBON / Limites à l'échelle du projet
        if (this.can_see_page && prc.can_project_access === '1') {
          if (moment().isSameOrBefore(prc_start_date)) {
            this.can_see_page = false
            this.AccessDeniedMessage = 'ProjectAccessNotYetOpened'
            this.AccessDeniedInfos = prc_start_date
          } else if (moment().subtract(1, 'd').isSameOrAfter(prc_end_date)) {
            this.can_see_page = false
            this.AccessDeniedMessage = 'ProjectAccessExpired'
            this.AccessDeniedInfos = prc_end_date
          } else {
            this.can_see_page = true
          }
        } else {
          this.can_see_page = false
          this.AccessDeniedMessage = 'ProjectAccessDeniedFull' 
          this.AccessDeniedInfos = 'project access invalid'
        }
      }
    },


    // ROYBON / Charge les infos sur le projet, basées sur le project_slug
    getProjectInfos () {
      this.project_slug = this.$route.params.project_slug
      let params = ''
      let rimnat_api_call_url = ''
      const slug_hash = md5(sha1(this.project_slug).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      params = `project_slug=${this.project_slug}&slug_hash=${slug_hash}`
      rimnat_api_call_url = `${this.api_server_baseurl}/public/${this.getProjectBySlug}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (null !== response) {
            if (response.data != null && response.data != 'null') {
              if (!isNaN(response.data.project_id)) {
                this.project = response.data 
                if (this.project.keywords != null && this.project.keywords != 'null' && this.project.keywords.length > 0) {
                  this.projectKeywords = JSON.parse(this.project.keywords)
                } else {
                  this.projectKeywords = []
                }
                // decode
                this.project.project_nicename = decodeURIComponent(this.project.project_nicename)
              } else {
                this.$router.push('/pages/error-404').catch(() => {})
              }
            } else {
              this.$router.push('/pages/error-404').catch(() => {})
            }
          } else {
            this.$router.push('/pages/error-404').catch(() => {})
          }
        })
        .catch((error)   => { console.log(error) })
    }, 

    // ROYBON / Charge les droits du current_user
    getCurrentUserProjectCaps () {
      // const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&user_id=${this.$store.state.AppActiveUser.user_id}`
      const user_hash = md5(sha1(this.$store.state.AppActiveUser.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const params = `project_id=${this.project.project_id}&user_id=${this.$store.state.AppActiveUser.user_id}&user_hash=${user_hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserProjectCaps}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data !== null && response.data != 'null' && response.data[0] != 'null') {
            this.current_user_project_caps = response.data[0] 
          }
          this.canSeePage()
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Initialisation de la position depuis BDD
    initProjectLocation ()  {
      if (!isNaN(this.project.project_lat) && !isNaN(this.project.project_lng)) {
        this.project_lat = parseFloat(this.project.project_lat)
        this.project_lng = parseFloat(this.project.project_lng)
      }
    },
    // ROYBON / Initialisation Google Map
    initGoogleMap () {
      if (!isNaN(this.project_lat) && !isNaN(this.project_lng) && this.project_lat !== 0 && this.project_lng !== 0) {
        this.center.lat = this.project_lat
        this.center.lng = this.project_lng
        this.markers[0].position.lat = this.project_lat
        this.markers[0].position.lng = this.project_lng
        this.markers[0].infoText = this.project.project_slug
      }
    },
    // ROYBON / recherche de lieux Google Maps
    setPlace (place) {
      this.place = place
      this.usePlace()
    },
    usePlace () {
      if (this.place) {
        this.updateProjectLocationStart()
        this.project_lat = this.place.geometry.location.lat()
        this.project_lng = this.place.geometry.location.lng()
        this.initGoogleMap()
        this.forceMapRerender()
        this.markerMoved = true
      }
    },
    // ROYBON / Update project location
    updateProjectLocation (evnt) {
      this.jdata = {'geo': {'lat':evnt.lat(), 'lng':evnt.lng()}}
      this.markerMoved = true
      this.project_lat = this.jdata.geo.lat
      this.project_lng = this.jdata.geo.lng
      // notification
      this.$vs.notify({
        color:'grey',
        text:this.$t('PressSaveOrCancel')
      })
    }, 

    // ROYBON / Début d'édition de la position
    updateProjectLocationStart () {
      // définit une position sur la Tour Eiffel si aucune position n'est encore définie
      if (this.project_lat === 0) {
        this.project_lat = this.eiffel_lat
        this.project_lng = this.eiffel_lng
      }
      this.markerEditionInProgress = true      
      // notification
      this.$vs.notify({
        color:'success',
        title:this.$t('ProjectUpdateStarted'),
        text:this.$t('ProjectLocationUpdateStarted')
      })
    },
    // ROYBON / Sauvegarde d'édition de la position
    updateProjectLocationSave () {
      this.markerEditionInProgress = false
      // sauvegarde de la nouvelle position 
      let params = ''
      let rimnat_api_call_url = ''

      // obtient la valeur issue d'une recherche d'adresse
      //params = `project_id=${this.project.project_id}&project_lat=${this.project_lat}&project_lng=${this.project_lng}`
      params = new FormData()
      params.set('project_id', `${this.project.project_id}`)
      params.set('project_lat', `${this.project_lat}`)
      params.set('project_lng', `${this.project_lng}`)
      rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.setProjectLocation}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          // si OK
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // notification
            this.$vs.notify({
              color:'success',
              title:this.$t('UpdateConfirmed'),
              text:this.$t('ProjectLocationUpdated')
            })
            this.markerMoved = false
            logActivity.add('project_location_update', `{lat:${this.project_lat}, lng:${this.project_lng}}`, null, null, this.project.project_id)
          }
        })
        .catch((error)   => { 
          console.log(error) 
          // notification
          this.$vs.notify({
            color:'danger',
            title:this.$t('UpdateError'),
            text:this.$t('ProjectLocationNotUpdated')
          })
          this.markerMoved = false
        })
    },
    // ROYBON / Annulation d'édition de la position
    updateProjectLocationCancel () {
      if (this.markerMoved) {
        this.initProjectLocation()
        this.forceMapRerender()      
      }
      this.markerEditionInProgress = false
      // notification
      this.$vs.notify({
        color:'warning',
        title:this.$t('UpdateCanceled'),
        text:this.$t('ProjectLocationNotUpdated')
      })
      this.markerMoved = false
      // détecte si position bidon non modifiée
      if (this.project_lat === this.eiffel_lat) {
        this.project_lat = 0
      }
      if (this.project_lng === this.eiffel_lng) {
        this.project_lng = 0
      }
    },

    // ----------------------------------------
    // ROYBON / Ouvre la sidebar d'ajout de user
    openAddUserSidebar () {
      this.usersSidebarVisible = true
    },   

    // ROYBON / Construit la liste des users du portal
    buildPortalUsersList () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUsers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.portalUsers = response.data
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Selection d'un user
    addProjectUserConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'warning',
        title: this.$t('Confirm'),
        text: `${this.$t('addUserConfirmationMessage')} ${this.userToAdd.first_name[0].toUpperCase() + this.userToAdd.first_name.slice(1)} ${this.userToAdd.last_name[0].toUpperCase() + this.userToAdd.last_name.slice(1)} ?`,
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.addProjectUserAction
      })
    },
    // ROYBON / Selection d'un user
    addProjectUserAction () {
      //const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&user_id=${this.userToAdd.user_id}`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('project_id', `${this.project.project_id}`)
      params.set('user_id', `${this.userToAdd.user_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.addProjectUser}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // log activity
            logActivity.add('project_user_allocation', this.userToAdd.user_id, null, null, this.project.project_id)
            logActivity.add('project_user_arrival', 'success', this.userToAdd.user_id, null, this.project.project_id)

            this.$vs.notify({
              color:'success',
              title: this.$t('projectAddUserSuccess')
            })
            this.$vs.notify({
              color:'success',
              title: this.$t('changeUserCapabilities')
            })
          } else if (response.data[0] === 'exists' || response.data == 'exists') {
            this.$vs.notify({
              color:'warning',
              title: this.$t('projectUserExists')
            })
          } else {
            this.$vs.notify({
              color:'danger',
              title: this.$t('projectAddUserError')
            })
          }
          this.buildProjectUsersList()
        })
        .catch((error)   => { console.log(error) })
    },

    // ----------------------------------------
    // ROYBON / Ouvre la sidebar d'ajout de layer
    openAddLayerSidebar () {
      this.layersSidebarVisible = true
    },   

    // ROYBON / Confirme l'ajout d'un layer
    addLayerConfirm (layer) {
      // check si intégration possible
      if (layer.layer_type == 'shapefile' && (this.project.project_coordinate_system == null || this.project.project_coordinate_system == '0')) {
        // intégration shapefile impossible sans sytème de coordonnées
        this.$vs.notify({
          color:'danger',
          title:this.$t('coordinatesSystemRequired'),
          text:this.$t('coordinatesSystemRequiredExplained')
        })
      } else {
        // check si layer déjà alloué
        const alreadyAllocated = this.isLayerAllocatedToProject(layer.layer_id)
        if (alreadyAllocated !== -1) {
          // layer déjà alloué
          this.$vs.notify({
            color:'danger',
            title:this.$t('layerAlreadyAllocated'),
            text:this.$t('layerCannotBeAllocatedTwice')
          })
        } else {
          // layer pas encore alloué au projet : demande de confirmation
          this.layerToAdd = layer
          this.$vs.dialog({
            type: 'confirm',
            color: 'warning',
            title: this.$t('ConfirmLayerAddition'),
            text: `${decodeURIComponent(layer.layer_name)} : ${this.$t('ProjectLayerAdditionMessage')}`,
            acceptText: this.$t('Confirm'),
            cancelText: this.$t('Cancel'),
            accept: this.addLayerAction,
            cancel: this.openAddLayerSidebar
          })    
        }
      }
    },

    // ROYBON / Teste si un layer est déjà affecté au projet
    isLayerAllocatedToProject (layer_id) {
      if (this.projectLayers != null && this.projectLayers.length > 0) {
        return this.projectLayers.findIndex(x => x.layer_id === layer_id)
      } else {
        return -1
      }
    },

    // ROYBON / Execute l'ajout d'un layer
    addLayerAction () {
      //const params = `project_id=${this.project.project_id}&layer_id=${this.layerToAdd.layer_id}`
      const params = new FormData()
      params.set('layer_id', `${this.layerToAdd.layer_id}`)
      params.set('project_id', `${this.project.project_id}`)
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.createProjectLayerAllocation}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.$vs.notify({
              color: 'success',
              title: this.$t('ConfirmedAllocation'),
              text: this.$t('ProjectLayerAllocationConfirmationMessage')
            })
            this.layerToAdd = ''
            this.buildProjectLayerList()
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedAllocation')
            })
          }
          logActivity.add('project_layer_allocation', response.data[0], null, null, this.project.project_id, this.layerToAdd.layer_id)
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          this.$vs.notify({
            color:'danger',
            title:this.$t('FailedAllocation')
          })
        })
    },

    // ROYBON / Ouvre la sidebar d'ajout de layer
    layerIcon (layer_type) {
      if (layer_type === 'mesh') { return 'icon-triangle' }
      if (layer_type === 'pointcloud') { return 'icon-cloud' }
      if (layer_type === 'structured_pointcloud') { return 'icon-cloud-snow' }
      if (layer_type === 'bim') { return 'icon-square' }
      if (layer_type === 'shapefile') { return 'icon-circle' }
      if (layer_type === 'dao') { return 'icon-edit-3' }
      if (layer_type === 'pano360') { return 'icon-globe' }
    },

    // ROYBON / Début d'édition des layers
    updateLayersStart () { 
      this.layersManageInProgress = true    
      // notification
      this.$vs.notify({
        color:'grey',
        title:this.$t('EditionStarted'),
        text:this.$t('UpdatesWillBeInstant')
      })
    },

    // ROYBON / Annulation d'édition des layers
    updateLayersFinish () {
      this.layersManageInProgress = false      
      this.layersSidebarVisible = false

      // notification
      this.$vs.notify({
        color:'success',
        title:this.$t('EditionFinished'),
        text:this.$t('UpdatesSaved')
      })
      this.buildProjectLayerList()
    },

    async deleteSelectedLayers () {
      await this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('ConfirmRemoval'),
        text: this.$t('ProjectLayerRemovalMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: () => {
          for (const layerId of this.bulkEditSelection) { 
            this.LayerToRemove = layerId
            this.removeProjectLayerAction()
          }
          this.bulkEditSelection = []
        }
      })
    },

    // ROYBON / Retire l'affectation d'un layer au project
    removeProjectLayerConfirm (layer_id) {
      this.LayerToRemove = layer_id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('ConfirmRemoval'),
        text: this.$t('ProjectLayerRemovalMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.removeProjectLayerAction
      })
    },
    removeProjectLayerAction () {
      if (this.LayerToRemove !== '') {
        // update de la BDD
        let params = ''
        let rimnat_api_call_url = ''
        //params = `layer_id=${this.LayerToRemove}&project_id=${this.project.project_id}`
        params = new FormData()
        params.set('layer_id', `${this.LayerToRemove}`)
        params.set('project_id', `${this.project.project_id}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.deleteProjectLayerAllocation}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              // success
              this.$vs.notify({
                color: 'success',
                title: this.$t('ConfirmedRemoval'),
                text: this.$t('ProjectLayerRemovalConfirmationMessage')
              })
              this.LayerToRemove = ''
              this.buildProjectLayerList()
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('FailedRemoval')
              })
            }
            logActivity.add('project_layer_removal', response.data[0], null, null, this.project.project_id, this.LayerToRemove)

          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedRemoval')
            })
          })
      }
    },
    
    // ROYBON / Retire les dates de planification 4D sur un couche
    removeProjectLayer4dPlanification (layer_id) {
      // update de la BDD
      let params = ''
      let rimnat_api_call_url = ''
      //params = `layer_id=${layer_id}&project_id=${this.project.project_id}`
      params = new FormData()
      params.set('layer_id', `${layer_id}`)
      params.set('project_id', `${this.project.project_id}`)
      rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.deleteProjectLayer4dPlanification}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.$vs.notify({
              color: 'success',
              title: this.$t('ConfirmedRemoval'),
              text: this.$t('RemoveLayer4dPlanificationConfirmation')
            })
            this.buildProjectLayerList()
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedRemoval')
            })
          }
          logActivity.add('project_layer_4d_removal', response.data[0], null, null, this.project.project_id, layer_id)

        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('FailedRemoval')
          })
        })

    },

    eventTester (event) {
      console.log(event)
    },

    // ROYBON / Application d'un changement de paramètre sur les layers
    set_ProjectLayerParameter (layer_id, parameter, event, ref = '') {
      console.log('set_ProjectLayerParameter')
      let value = null
      // Interprète la valeur
      if (typeof event === 'string') {
        value = event
      } else if (typeof event === 'number') {
        value = event.toString()
      } else if (typeof event === 'object') {
        if (event.type === 'blur') {
          value = event.target.innerText.trim()
          console.log(value)
        }
        if (typeof event.target != 'undefined') {
          if (event.target.type === 'checkbox') {
            if (parameter === 'default_pointcloud_material_pointsizetype') {
              value = event.target.checked ? 'ADAPTIVE' : 'FIXED' 
            } else {
              value = event.target.checked ? '1' : '0' 
            }
          } else if (event.type === 'change') {
            value = event.target.value ? event.target.value : '' 
          }
        } else if (typeof event[0] == 'object') {
          value = JSON.stringify(event)
        }
      }
 
      if (value != null) {
        // met à jour l'état d'affichage du color picker associé ou non le color picker
        if (parameter === 'default_pointcloud_material_style') {
          // utilise l'enventuel sender
          if (ref !== '') {
            this.$refs[ref][0].innerHTML = value 
          } 
          const layer_id = ref.split('_')[0]
          this.$set(this.DisplayColorPicker, layer_id, event === 'COLOR')
          this.$set(this.ForcedColors, layer_id, value)

        } else if (parameter === 'shp_point_color') {
          this.$set(this.shpPointColors, layer_id, value)
          
        } else if (parameter === 'shp_polygon_fill_color') {
          this.$set(this.shpFillColors, layer_id, value)
          
        } else if (parameter === 'shp_polygon_outline_color') {
          this.$set(this.shpOutlineColors, layer_id, value)
          
        }

        // sauvegarde de la nouvelle valeur de paramètre
        let params = ''
        let rimnat_api_call_url = ''
        params = new FormData()
        params.set('layer_id', `${layer_id}`)
        params.set('project_id', `${this.project.project_id}`)
        params.set('value', `${value}`)
        params.set('parameter', `${parameter}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.setProjectLayerParameter}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('project_layer_parameter_update', parameter, null, null, this.project.project_id, layer_id)
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          })
      }
    },

    // ROYBON / Gère les valeurs par défaut des paramètres de layers
    refreshPointcloudStates () {
      for (const lay in this.projectLayers) {
        const layer_id = this.projectLayers[lay].layer_id
        if (this.projectLayers[lay].layer_type === 'pointcloud' || this.projectLayers[lay].layer_type === 'structured_pointcloud') {
          if (this.projectLayers[lay].default_pointcloud_material_style === 'COLOR') {            
            this.$set(this.DisplayColorPicker, layer_id, true)
          } else {
            this.$set(this.DisplayColorPicker, layer_id, false)
          }
          this.$set(this.ForcedColors, layer_id, this.projectLayers[lay].default_pointcloud_material_color)
          this.$set(this.PixelSizes, layer_id, this.projectLayers[lay].default_pointcloud_material_size || 1)
          this.$set(this.Min4D, layer_id, this.projectLayers[lay].layer_4d_min)
          this.$set(this.Max4D, layer_id, this.projectLayers[lay].layer_4d_max)

        } else if (this.projectLayers[lay].layer_type === 'shapefile') {

          this.$set(this.DisplayColorPicker, layer_id, true)

          this.$set(this.shpFillColors, layer_id, this.projectLayers[lay].shp_polygon_fill_color || '#000')
          this.$set(this.shpOutlineColors, layer_id, this.projectLayers[lay].shp_polygon_outline_color || '#000')
          this.$set(this.shpPointColors, layer_id, this.projectLayers[lay].shp_point_color || '#000')
          this.$set(this.Min4D, layer_id, this.projectLayers[lay].layer_4d_min)
          this.$set(this.Max4D, layer_id, this.projectLayers[lay].layer_4d_max)

        } else {
          this.$set(this.Min4D, layer_id, this.projectLayers[lay].layer_4d_min)
          this.$set(this.Max4D, layer_id, this.projectLayers[lay].layer_4d_max)
        }
      }
    }, 

    // ----------------------------------------
    // ROYBON / Début d'édition des users
    updateUsersStart () { 
      this.usersEditionInProgress = true    
      // notification
      this.$vs.notify({
        color:'grey',
        title:this.$t('EditionStarted'),
        text:this.$t('UpdatesWillBeInstant')
      })
    },

    // ROYBON / Annulation d'édition des users
    updateUsersFinish () {
      this.usersEditionInProgress = false      
      this.usersSidebarVisible = false
      // notification
      this.$vs.notify({
        color:'success',
        title:this.$t('EditionFinished'),
        text:this.$t('UpdatesSaved')
      })
      this.buildProjectUsersList()
    }, 

    // ROYBON / Application d'un changement de paramètre sur le user
    set_ProjectUserCap  (user_id, parameter, event) {
      let value = ''
      // Interprète la valeur
      if (typeof event === 'object') {
        if (event.target.type === 'checkbox') {
          value = event.target.checked ? '1' : '0' 
        } else if (event.target.type === 'date') {
          value = event.target.value ? event.target.value : 'null' 
        }
      } else if (event === '') {
        value = 'null'
      }
 
      if (value) {
        // sauvegarde de la nouvelle valeur de paramètre
        let params = ''
        let rimnat_api_call_url = ''
        const urlEncodedValue = encodeURIComponent(value)
        //params = `user_id=${user_id}&portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&parameter=${parameter}&value=${urlEncodedValue}`
        params = new FormData()
        params.set('user_id', `${user_id}`)
        params.set('portal_id', `${this.$store.state.portal_id}`)
        params.set('project_id', `${this.project.project_id}`)
        params.set('parameter', `${parameter}`)
        params.set('value', `${urlEncodedValue}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.setProjectUserCap}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('project_user_cap_update', parameter, null, null, this.project.project_id)
              logActivity.add('project_user_cap_updated', parameter, user_id, null, this.project.project_id)

              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
              // update le current_user s'il se modifie lui même
              if (user_id === this.$store.state.AppActiveUser.user_id) {
                this.getCurrentUserProjectCaps()
              } else {
                this.buildProjectUsersList()
              }
            } else {
              // error
              if (event !== '') {
                if (event.target.type === 'checkbox') {
                  event.target.checked = !event.target.checked
                }
              }
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          })
      }
    },

    // ROYBON / Gère les valeurs par défaut des paramètres de layers
    refreshUsersDates () {
      for (const user in this.projectUsers) {
        const user_id = this.projectUsers[user].user_id
        this.$set(this.project_access_start_dates, user_id, this.projectUsers[user].can_project_access_start_date)
        this.$set(this.project_access_end_dates, user_id, this.projectUsers[user].can_project_access_end_date)
      }
    }, 

    // ROYBON / Supprime le user
    removeProjectUserConfirm (user_id) {
      this.userToRemove = user_id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('ConfirmRemoval'),
        text: this.$t('ProjectUserRemovalMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.removeProjectUserAction
      })
    }, 
    removeProjectUserAction () {
      if (this.userToRemove !== '') {
        // update de la BDD
        let params = ''
        let rimnat_api_call_url = ''
        //params = `user_id=${this.userToRemove}&project_id=${this.project.project_id}`
        params = new FormData()
        params.set('user_id', `${this.userToRemove}`)
        params.set('project_id', `${this.project.project_id}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.deleteProjectUser}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('project_user_remove', this.userToRemove, null, null, this.project.project_id)
              logActivity.add('project_user_removed', 'success', this.userToRemove, null, this.project.project_id)
              // success
              this.$vs.notify({
                color: 'success',
                title: this.$t('ConfirmedRemoval'),
                text: this.$t('ProjectUserRemovalConfirmationMessage')
              })
              this.userToRemove = ''
              this.buildProjectUsersList()
            } else {
              // error
              this.$vs.notify({
                color:'danger',
                title:this.$t('FailedRemoval')
              })
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedRemoval')
            })
          })
      }
    }, 

    // ----------------------------------------
    // ROYBON / Début d'édition des tags
    updateTagsStart () { 
      this.tagsEditionInProgress = true    
      // notification
      this.$vs.notify({
        color:'grey',
        title:this.$t('EditionStarted'),
        text:this.$t('UpdatesWillBeInstant')
      })
    },

    // ROYBON / Annulation d'édition des tags
    updateTagsFinish () {
      this.tagsEditionInProgress = false      
      // notification
      this.$vs.notify({
        color:'success',
        title:this.$t('EditionFinished'),
        text:this.$t('UpdatesSaved')
      })
      this.buildProjectLayerList()
    }, 

    // ROYBON / Affichage d'une popup permettant de prévisualiser le média associé à un tag
    mediaPreview (media_id) {
      const params = `media_id=${media_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/media/${this.getMediaUrl}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.popupImageUrl = response.data
          this.popupImageActive = true
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Retire un tag
    removeProjectTagConfirm (tag_id) {
      this.TagToRemove = tag_id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('ConfirmRemoval'),
        text: this.$t('ProjectTagRemovalMessage'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.removeProjectTagAction
      })
    },
    removeProjectTagAction () {
      if (this.TagToRemove !== '') {
        // update de la BDD
        let params = ''
        let rimnat_api_call_url = ''
        //params = `tag_id=${this.TagToRemove}&project_id=${this.project.project_id}`
        params = new FormData()
        params.set('tag_id', `${this.TagToRemove}`)
        params.set('project_id', `${this.project.project_id}`)
        rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.deleteProjectTag}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('project_tag_remove', this.TagToRemove, null, null, this.project.project_id)
              // success
              this.$vs.notify({
                color: 'success',
                title: this.$t('ConfirmedRemoval'),
                text: this.$t('ProjectTagRemovalConfirmationMessage')
              })
              this.TagToRemove = ''
              // this.buildProjectTagsList()
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('FailedRemoval')
              })
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('FailedRemoval')
            })
          })
      }
    }, 

    // ROYBON / Récupère l'évènement de changement sur une value de tag 
    tagChanged (event, tag_id, parameter, newValue = '') {
      let value = ''
      // isole la valeur finale
      if (event.type === 'change') {
        if (event.target.type === 'text' || event.target.type === 'color') {
          value = decodeURIComponent(event.target.value)
        } else if (event.target.type === 'radio') {
          value = newValue
        }
        const params = new FormData()
        params.set('tag_id', `${tag_id}`)
        params.set('project_id', `${this.project.project_id}`)
        params.set('parameter', `${parameter}`)
        params.set('value', `${value}`)
        const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.updateProjectTag}`
        axios.post(rimnat_api_call_url, params)
          .then((response) => { 
            if (response.data[0] === 'ok' || response.data == 'ok') {
              logActivity.add('project_tag_update', tag_id, null, null, this.project.project_id)
              // success
              this.$vs.notify({
                color:'success',
                title:this.$t('UpdateConfirmed'),
                text:this.$t('ParameterUpdated')
              })
            } else {
              // error
              event.target.checked = !event.target.checked
              this.$vs.notify({
                color:'danger',
                title:this.$t('UpdateError'),
                text:this.$t('ParameterNotUpdated')
              })
            }
          })
          .catch((error)   => { 
            console.log(error) 
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('UpdateError'),
              text:this.$t('ParameterNotUpdated')
            })
          })
      }

    },
    
    // ROYBON / Construit la liste des layers disponibles 
    buildPortalLayersLists () {      
      let params = ''
      let rimnat_api_call_url = ''
      // Unused layers
      params = `portal_id=${this.$store.state.portal_id}`
      rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUnusedLayers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.portalUnusedLayers = response.data 
        })
        .catch((error)   => { console.log(error) })
      // USED layers
      params = `portal_id=${this.$store.state.portal_id}`
      rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalUsedLayers}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.portalUsedLayers = response.data 
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Récupère les demandes d'accès sur le projet
    getAccessRequests () {   
      const params = `portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/project/${this.getProjectAccessRequests}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != 'null') {
            if (response.data != null && response.data != 'null' && typeof response.data == 'object') this.accessRequests = response.data 
          }
        })
        .catch((error)   => { console.log(error) })
    }, 

    // ROYBON / Récupère les infos pour construire le fichier RDP pour l'accès via le GPU server side
    getRdpData (app) {
      const params = `user_id=${this.$store.state.AppActiveUser.user_id}&portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&app=${app}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/rdp/${this.GenerateRdpFile}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          console.log(response)
          // get content and password
          if (response.data != null && response.data != 'null' && typeof response.data == 'object') {
            this.rdpContent = response.data.rdp_content
            this.rdpPassword = response.data.password
            if (this.rdpContent != '' && this.rdpPassword != '') {
              // display explainations
              this.rdpPopupActive = true
              // download after delay
              setTimeout(() => this.downloadRdpFile(), 5000)
            }
          }
        })
        .catch((error)   => { console.log(error) })
    }, 
    // ROYBON / Télécharge le fichier RDP
    downloadRdpFile () {
      if (this.rdpContent != '' && this.rdpPassword != '' && this.rdpPopupActive == true) {
        const blob = new Blob([this.rdpContent], {type: 'text/plain'})
        const e = document.createEvent('MouseEvents')
        const a = document.createElement('a')
        a.download = `${this.$store.state.AppActiveUser.first_name}_${this.$store.state.AppActiveUser.last_name}-${this.project.project_nicename}.rdp`
        a.href = window.URL.createObjectURL(blob)
        a.dataset.downloadurl = ['text/json', a.download, a.href].join(':')
        e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
        a.dispatchEvent(e)
      }
    },
    rdpPasswordCopiedAlert () {
      this.$vs.notify({
        title: this.$t('success'),
        text: this.$t('TextCopySuccess'),
        color: 'success',
        iconPack: 'feather',
        position: 'top-center',
        icon: 'icon-check-circle'
      })
    }
  },

  computed: {
    rdpAccessEnabled () {
      return this.$store.state.rdp == true
    },
    rdpAccessAvailable () {
      const result = {
        value: true,
        cause: null
      }
      const diag = this.$store.state.userDiagnostic
      if (typeof diag == 'object' && diag != null) {
        // test OS
        if (diag.device.os.name.toLowerCase() != 'windows') {
          result.value = false
          result.cause = 'OS not supported'
        } else if (diag.device.os.version.toLowerCase() != 'nt 10.0') {
          result.value = false
          result.cause = 'OS version not supported'
        } else  if (diag.device.os.versionName.toLowerCase() != '10') {
          result.value = false
          result.cause = 'OS version name not supported'
        // test country
        } else if (diag.ipData.country_code.toLowerCase() != 'fr') {
          result.value = false
          result.cause = 'IP location not supported'
        }
        // TODO test RDP server availability
      }
      return result
    },
    rdpAccessRecommended  () {
      const result = {
        value: false,
        cause: null
      }
      const diag = this.$store.state.userDiagnostic
      if (typeof diag == 'object' && diag != null) {
        if (diag.computedValues.speedTest != null && diag.computedValues.speedTest.quality != null && diag.computedValues.speedTest.quality < 90) {
          result.value = false
          result.cause = 'RDP not possible because connection is too slow'
        } else if (diag.computedValues.gpuIsNvidiaOrAmd == false || diag.computedValues.gpuIsIntel == true) {
          result.value = true
          result.cause = 'RDP recommended because client GPU is not compatible'
        } else if (diag.summaryReport.isBrowserSupported == false || diag.summaryReport.isBrowserUpToDate == false) {
          result.value = true
          result.cause = 'RDP recommended because client browser is not compatible'
        } else if (diag.summaryReport.webGL != true) {
          result.value = true
          result.cause = 'RDP recommended because client browser does not support WebGL'
        } else if (diag.summaryReport.gpuScore != null && diag.summaryReport.gpuScore > 0 && diag.summaryReport.gpuScore < 3000) {
          result.value = true
          result.cause = 'RDP recommended because client GPU benchmark is too low'
        }
      }
      return result
    },
    diagUrlWithRedirect () {
      return `/diagnostic?redirect=${this.$route.fullPath}`
    },
    diagnosticDone () {
      return typeof this.$store.state.userDiagnostic != 'undefined' && this.$store.state.userDiagnostic && typeof this.$store.state.userDiagnostic.summaryReport != 'undefined' && typeof this.$store.state.userDiagnostic.summaryReport.os != 'undefined'
    },
    // ROYBON / Listes filtrées des layers
    layersPointcloud () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'pointcloud')
    },
    layersStructuredPointcloud () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'structured_pointcloud')
    },
    layersMesh () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'mesh')
    },
    layersBim () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'bim')
    },
    layersShapefile () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'shapefile')
    },
    layersDao () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'dao')
    },
    layersPano360 () {
      return Object.entries(this.projectLayers)
        .map(item => item[1])
        .filter(item => item.layer_type == 'pano360')
    },
    layersCount () {
      return this.projectLayers.length || 0
    },
    showAccessRequestCard () {
      let returned = false
      if (this.accessRequests != null && this.numberOfPendingRequests > 0) {
        if (this.current_user_portal_caps.can_global_manage == '1') returned = true
        if (this.current_user_portal_caps.can_portal_users_manage == '1') returned = true
        if (this.current_user_project_caps.can_project_users_manage == '1') returned = true
      }
      return returned
    },
    viewerUrl () {
      // get default config
      let viewerBaseUrl = this.$appConfig.viewerBaseUrl
      const viewerVersion = this.$store.state.viewerVersion || 'viewer'
      // modify url with viewerVersion stored in VueX
      if (viewerVersion != null && viewerVersion != '') {
        viewerBaseUrl = viewerBaseUrl.replace('viewer', viewerVersion)
      }
      return viewerBaseUrl
    },
    modelUrl () { 
      let key = md5(this.project.project_id).toString(CryptoJS.enc.sha1)
      key = sha1(key).toString(CryptoJS.enc.sha1).slice(0, 10)
      const join_id = (Math.random() + 1).toString(36).substring(7)
      const user_hash = md5(sha1(this.$store.state.AppActiveUser.user_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const project_hash = md5(sha1(this.project.project_id.toString()).toString(CryptoJS.enc.sha1)).toString(CryptoJS.enc.md5)
      const url = `${this.viewerUrl}/?join_id=${join_id}&portal_id=${this.$store.state.portal_id}&project_id=${this.project.project_id}&k=${key}&encTk=${this.$store.state.AppActiveUser.encTk}&user_hash=${user_hash}&project_hash=${project_hash}`
      return url
    },
    canPortalManage () {
      if (this.$store.state.current_user_portal_caps !== null) {
        return this.$store.state.current_user_portal_caps.can_portal_projects_manage === '1'
      }
    },
    loggedIn () {
      return (this.$store.state.AppActiveUser.user_id !== null && parseInt(this.$store.state.AppActiveUser.uuid) !== '0')
    },
    isPublicModel () { 
      return this.project.project_status === 'public'
    },
    scrollbarTag () { 
      return this.$store.getters.scrollbarTag
    },
    editableMap () {
      if (this.current_user_project_caps.can_project_customize === '1' && this.can_see_page) { return true }
    }, 
    addLayersListStyle: function () {
      return `overflow-y: scroll; max-height: ${this.layersCardHeight - 130}px`
    }, 
    projectShareSlugError: function () {
      if (this.projectSharePortalSlug.toString() !== '' && !this.projectSharePortalSlugValid) {
        return true
      }
    },
    projectShareSlugSuccess: function () {
      if (this.projectSharePortalSlug.toString() !== '' && this.projectSharePortalSlugValid) {
        return true
      }
    },
    projectShareKeyError: function () {
      if (this.projectAddToOtherKey.toString() !== '' && !this.validProjectShareKey) {
        return true
      }
    },
    projectShareKeySuccess: function () {
      if (this.projectAddToOtherKey.toString() !== '' && this.validProjectShareKey) {
        return true
      }
    },
    validProjectShareKey: function () {
      if (this.projectAddToOtherKey.toString() === this.expectedCheck.toString() && this.expectedCheck.toString() !== '') {
        return true
      } else {
        return false
      }
    },
    validProjectShareInputs: function () {
      if (this.validProjectShareKey && this.projectSharePortalSlugValid === true) {
        return true
      } else {
        return false
      }
    },
    numberOfPendingRequests () {
      console.log(this.accessRequests)
      if (this.accessRequests != null && this.accessRequests.length > 0) {
        return this.accessRequests.map(item => item.closed)
          .filter(c => c === '0').length
      }
    }
  },

  beforeMount () {
    if (this.$store.state.AppActiveUser != null) {
      if (typeof this.$store.state.AppActiveUser.user_id != 'undefined' && this.$store.state.AppActiveUser.user_id && parseInt(this.$store.state.AppActiveUser.uuid) != '0' && typeof this.$store.state.AppActiveUser.email_verified != 'undefined' && this.$store.state.AppActiveUser.email_verified != false) {
        this.getProjectInfos()
      } else {
        this.$router.push('/').catch(() => {})
      }
    }
  },

  mounted () {
    this.buildCoordinatesSystemsList()
  },

  watch: {
    newKeywordFormActive: function () {
      if (this.newKeywordFormActive) {
        this.getPortalKeywords()
      }
    },
    layersDao: function () {
      if (this.layersDao.length > 0) {
        // récupère le style depuis le JSON sur data01
        this.layersDao.forEach((layer, index) => {
          // convertit la valeur string dxf_layers_styles en objet
          const dxf_layers_styles = layer.dxf_layers_styles
          if (typeof dxf_layers_styles == 'string') {
            const styleObject = JSON.parse(dxf_layers_styles)
            this.layersDao[index].dxf_layers_styles = styleObject
          }
          // confronte les valeurs en DB et celles du converter
          const layerUrl = layer.layer_url
          const layerUrlProxy = layerUrl.replace(this.$appConfig.data01BaseUrl, this.$appConfig.data01BaseUrlProxy)
          const currentTimestamp = new Date().getTime()
          const layerDaoJsonUrl = `${layerUrlProxy}/layers.json?timestamp=${currentTimestamp}`
          axios.get(layerDaoJsonUrl, {})
            .then((response) => { 
              let layerStyles = typeof response.data == 'object' ? response.data : JSON.parse(response.data)
              layerStyles = JSON.parse(JSON.stringify(layerStyles))
              if (layerStyles != null) {
                this.layersDaoConverterStyles.push({
                  'layer_id': this.layersDao[index].layer_id, 
                  'dxf_layers_styles': layerStyles
                })
                // ajoute l'info au layer si nécessaire, sinon garder l'info déjà présente
                if (this.layersDao[index].dxf_layers_styles == null || this.layersDao[index].dxf_layers_styles == '') {
                  this.layersDao[index].dxf_layers_styles = layerStyles
                  this.set_ProjectLayerParameter(this.layersDao[index].layer_id, 'dxf_layers_styles', layerStyles)
                  if (this.layersDaoDbStylesLoaded == false) {
                    this.layersDaoDbStylesLoaded = true
                    this.buildProjectLayerList()
                  }
                }
              }
            })
            .catch((error)   => { console.log(error) })

        })
        // this.layersDaoConverterStyles = 
      }

    },
    project: function () {
      // 2. obtient les droits du user sur ce projet    c
      this.getCurrentUserProjectCaps()

      // obtient le système de coordonnées actuel du projet
      if (this.project.project_coordinate_system !== null) {
        const currentCoordinatesSystemId = this.project.project_coordinate_system
        if (currentCoordinatesSystemId.length !== '0') {
          this.getCurrentCoordinatesSystem(currentCoordinatesSystemId)
        }
      }
      // récupère l'image de légende
      if (this.project.project_legend_media_id !== null && this.project.project_legend_media_id !== '0') {
        this.getLegendImage(this.project.project_legend_media_id)
      }
      // récupère la liste des portals ayant accès à ce project
      this.projectPortalsList() 

    },
    projectPortals: function () {
      if (this.projectPortals.length > 1) {
        this.multiPortalsProject = true
      }
      this.buildProjectUsersList()
    },

    current_user_project_caps: function () {
      // 3. détermine si page est accessible au user ou non  
      this.canSeePage()
      this.getAccessRequests()
    },
    can_see_page: function () {   
      // 4. charge les infos sur le projet 
      if (this.can_see_page) {
        this.initProjectLocation()
        this.buildProjectLayerList()
        // this.buildProjectTagsList()
        this.buildProjectUsersList()
        logActivity.add('project_access', 'success', null, null, this.project.project_id)
        logActivity.add('user_agent', navigator.userAgent)
      } else {
        logActivity.add('project_access_refused', 'error', null, null, this.project.project_id)
        logActivity.add('user_agent', navigator.userAgent)
      }
      this.loaded = true
    },
    loaded: function () {
    },

    project_lat: function () {
      this.initGoogleMap()
    },
    project_lng: function () {
      this.initGoogleMap()
    }, 
    projectUsers: function () {
      this.refreshUsersDates()
    },
    layersSidebarVisible: function () {
      if (this.layersSidebarVisible) { 
        this.buildPortalLayersLists()
      }
      this.layersCardHeight = this.$refs.layersCard.clientHeight
    },
    usersSidebarVisible: function () {
      if (this.usersSidebarVisible) { 
        this.buildPortalUsersList()
      }
    },
    projectLayers: function () {
      this.projectLayersVersion = this.projectLayersVersion + 1
      this.refreshPointcloudStates()
    },
    coordinatesSystemSelected: function () {
      if (this.project.project_coordinate_system === '0' || this.project.project_coordinate_system === null) {
        this.updateCoordinatesSystemConfirm()
      } else {
        const selected = this.coordinatesSystemSelected
        const current = this.currentCoordinatesSystem[0]
        if (selected.id !== current.id || !current) {
          this.updateCoordinatesSystemConfirm()
        } else {
          // notification
          this.$vs.notify({
            color:'warning',
            title:this.$t('selectedValueSameAsCurrent'),
            text:this.$t('noChangeWillBeMade')
          })
        }
      }
    },
    portalUsersSelected: function () {
      const selected = this.portalUsersSelected
      if (selected !== null) {
        if (selected.user_id !== null) {
          this.userToAdd = selected
          this.addProjectUserConfirm()
        }
      }
    },
    projectSharePortalSlug: function () {
      if (this.projectSharePortalSlug.toString() !== '' && this.projectSharePortalUserId.toString() !== '') {
        this.expectedCheck = sha1(`${this.projectSharePortalSlug}${this.projectSharePortalUserId}`)
      }
    },
    projectSharePortalUserId: function () {
      if (this.projectSharePortalSlug.toString() !== '' && this.projectSharePortalUserId.toString() !== '') {
        this.expectedCheck = sha1(`${this.projectSharePortalSlug}${this.projectSharePortalUserId}`)
      }
    }
  } 
}
</script>

<style lang="scss">
    .vs-collapse-item--content {
        overflow-y: auto;
    }
    .layerColorPreview {
        -webkit-text-stroke: 1px black;
    }
    .cp__wrapper {
        width: 200px;
        height: 160px;
    }
    .cp__fm-switcher{
      display:none;
    }
    .cp__fm-fields span {
      display:none;
    }
    .cp__saturation {
      padding-bottom: 30%;
    }
    .vs-tabs-primary .con-ul-tabs .activeChild button, .vs-tabs-primary .con-ul-tabs button {
      text-align: left!important;
    }
    @import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
    .keyword_popup {
      height: 300px;
    }
    .ribbon {
      width: 300px;
      height: 300px;
      overflow: hidden;
      position: absolute;
    }
    .ribbon::before,
    .ribbon::after {
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid rgb(139, 181, 23);
    }
    .ribbon span {
      position: absolute;
      display: block;
      width: 600px;
      padding: 10px 0;
      background-color: rgb(139, 181, 23);
      box-shadow: 0 5px 10px rgba(0,0,0,.5);
      color: #fff;
      font-size: 1.3rem;
      font-weight: 600;
      text-shadow: 0 2px 2px rgba(0,0,0,.5);
      text-transform: uppercase;
      text-align: center;
    }
    /* top right*/
    .ribbon-top-right {
      top: -10px;
      right: -10px;
    }
    .ribbon-top-right::before,
    .ribbon-top-right::after {
      border-top-color: transparent;
      border-right-color: transparent;
    }
    .ribbon-top-right::before {
      top: 0;
      left: 0;
    }
    .ribbon-top-right::after {
      bottom: 0;
      right: 0;
    }
    .ribbon-top-right span {
      left: -120px;
      top: 50px;
      transform: rotate(30deg);
    }
    .vx-card .vx-card__collapsible-content .card-overlay::after{
      background: rgba(0, 0, 0, 0)
    }
    .input_decimal{
      width:40px;
      padding: 0px;
      text-align:center;
    }
    .vs-list--slot{
      width:100%;
    }

    @media (max-width: 800px) {
      .vs-list--slot{
        width:100%;
      }
      .vs-list--icon{
        display: none;
      }
      .vs-list--item{
        display: inline-block;
      }
      .list-titles{
        width: 100%;
      }
    }
    .empty {
      visibility: hidden;
    }
    .td-edit {
      text-decoration: none;
    }
    .td-edit:before {
      content:'✎'; 
      visibility: visible;
    }
    .tr-expand {
      background-color: #fcffd1;
    }
    .img100 {
      width: 100%;
      vertical-align: middle;
      margin-left: auto;
      margin-right: auto;
    }
    .vs-con-input-label {
      width: 90%;
    }
    .caps {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
    }
    .cap {
      margin-top: 2px;
      margin-bottom: 2px;
    }
    .editable {
      background-color: fff;
      border: 1px dashed rgba(0,0,0,.1);
    }
    .editable:before {
      content:'✎'; 
      visibility: visible;
    }
    .addLayerActions {
      float: right;
    }
    .line-break {
      word-break: break-all
    }
    .layer_actions {
      width: 850px;
      float: right;
    }
    .vs-sidebar .vs-sidebar--items .scrollable{
      overflow-y: scroll;
      // max-height: 230px;
    }
    .sidebar-alert {
      padding-left: 8px;
      padding-right: 8px;
    }
    .vs-sidebar {
      z-index: 99999;
    }
    .legend {
      height: 300px;
      display: contents;
    }
    .legend_img {
      max-width: 100%;
      height: auto;
      display: contents;
    }
    .userAddColOne {
      width:2px;
    }
    .input_date{
      cursor:pointer;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 20px;
      margin: 0px;
      width: 125px;
      text-align:left;
      background-color:transparent;
      border-bottom-style:none;
      border-left-style:none;
      border-right-style:none;
      border-top-style:none;
      &::-webkit-clear-button {
        display:none;
      }
      &::-webkit-inner-spin-button {
        display:none;
      }
      &::-webkit-calendar-picker-indicator { 
        // display:none;
      }
    }

</style>
